.elaine-dialog-container-full{
  .elaine-dialog-full-inner{
    .elaine-dialog-directive-container{
      > .e_form{
        height: 100%;
      }
      width: 100%;
      height: 100%;
      display: block;
    }
    .elaine-dialog-loading{
      .elaine-spinner{
        top: 25%;
      }
      &.ng-hide-add,&.ng-hide-remove{
        -webkit-transition: .35s ease-in-out all;
        -moz-transition: .35s ease-in-out all;
        -ms-transition: .35s ease-in-out all;
        -o-transition: .35s ease-in-out all;
        transition: .35s ease-in-out all;
      }
      &.ng-hide{
        opacity: 0;
      }
      opacity: 1;
      display:block;
      width: 100%;
      height: calc( 100% - 30px );
      position: absolute;
      z-index: 1001;
      top: 0;
      left: 0;
      background: #282e2c;
    }
    width: 100%;
    height: calc( 100% - 30px );
    display: block;
  }
  .elaine-dialog-full-footer{
    button{
      &[ disabled ]{
        opacity: .50;
        cursor: default;
      }
      &:hover:not( [ disabled ] ), &:focus:not( [ disabled ] ) {
        color: #fefefe;
        background: #9dc427;
      }
      &.cancel,&.close{
        &:hover:not( [ disabled ] ), &:focus:not( [ disabled ] ) {
          color: #fefefe;
          background: #731513;
        }
      }
      &:not( :first-of-type ){
        margin-right: 30px;
      }
      display: inline-block;
      border-radius: 0;
      border-color: transparent;
      border-top: none;
      border-bottom: none;
      background: #282e2c;
      color: #fefefe;
      line-height: 1;
      height: 100%;
      float: right;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      min-width: 100px;
      text-transform: uppercase;
    }
    width: 100%;
    height: 30px;
    display: block;
    border-top: 1px solid #000;
  }
  background: #282e2c;
  color: #fefefe;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.elaine-dialog-container{
  &.elaine-modal-default{
    .elaine-dialog{
      &.animated{
        &.ng-enter,
        &.ng-leave {
          transition: top linear 0.5s;
        }
        &.ng-enter {
          &.ng-enter-active {
            top: 25px;
          }
        }
        &.ng-enter,
        &.ng-leave-active {
          top: -100%;
        }
      }

      .elaine-dialog-right{
        &.initing{
          overflow: hidden;
        }
        .elaine-dialog-right-header{
          .elaine-dialog-title{
            font-size: 16px;
            padding: 0 10px;
            text-transform: uppercase;
            color: #9dc427;
          }
          button{
            &:hover{
              color: #fefefe;
              background: #731513;
            }
            width: 30px;
            height: 100%;
            border: none;
            border-left: 1px solid #000;
            background: transparent;
            position: absolute;
            right: 0;
            outline: none;
          }
          *{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          width: 100%;
          height: 30px;
          display: block;
          border-bottom: 1px solid #000;
          cursor: move;
          position: relative;
          line-height: 30px;
        }
        .elaine-dialog-right-inner{
          .elaine-dialog-directive-container{
            width: 100%;
            height: 100%;
            display: block;
          }
          &.wizard-animation{
            &.wizard-animation-next{
              .elaine-dialog-wizard-inner {
                .elaine-wizard-dialog-directive-parent {
                  &.ng-enter {
                    left: 100%;
                  }
                  &.ng-enter.ng-enter-active {
                    left: 0;
                  }
                  &.ng-leave {
                    left: 0;
                  }
                  &.ng-leave.ng-leave-active {
                    left: -100%;
                  }
                }
              }
            }
            &.wizard-animation-prev{
              .elaine-dialog-wizard-inner {
                .elaine-wizard-dialog-directive-parent {
                  &.ng-enter {
                    right: 100%;
                  }
                  &.ng-enter.ng-enter-active {
                    right: 0;
                  }
                  &.ng-leave {
                    right: 0;
                  }
                  &.ng-leave.ng-leave-active {
                    right: -100%;
                  }
                }
              }
            }
            overflow: hidden;
          }
          .elaine-dialog-wizard-inner {
            .elaine-wizard-dialog-directive-parent {
              .elaine-wizard-dialog-directive-container {
                .elaine-wizard-header{
                  .elaine-wizard-header-title{
                    line-height: 30px;
                    font-size: 16px;
                    padding: 0 10px;
                    margin: 0;
                    width: auto;
                    height: 100%;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  width: 100%;
                  height: 30px;
                  display: block;
                  border-bottom: 1px solid #000;
                }
                width: 100%;
                height: 100%;
                display: block;
              }
              &.ng-enter,
              &.ng-leave {
                -webkit-transition: 750ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
                -moz-transition: 750ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
                -ms-transition: 750ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
                -o-transition: 750ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
                transition: 750ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
              }
              width: 100%;
              height: 100%;
              display: block;
              position: absolute;
            }
            height: 100%;
            position: relative;
            display: block;
          }
          .elaine-dialog-loading{
            &.elaine-dialog-loading-not-inited{
              background: #282e2c;
            }
            .elaine-spinner{
              top: 25%;
            }
            display:block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1001;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.1);
          }
          &.overflowHidden{
            overflow: hidden;
          }
          position: relative;
          width: 100%;
          height: calc( 100% - 60px );
          display: inline-block;
          vertical-align: top;
        }
        .elaine-dialog-right-footer{
          button{
            &[ disabled ]{
              opacity: .50;
              cursor: default;
            }
            &:hover:not( [ disabled ] ), &:focus:not( [ disabled ] ) {
              color: #fefefe;
              background: #9dc427;
            }
            &.cancel, &.close {
              &:hover:not( [ disabled ] ), &:focus:not( [ disabled ] ) {
                color: #fefefe;
                background: #731513;
              }
            }
            &:not( first-of-type ) {
              margin-left: 30px;
            }
            &:first-of-type{
              border-right: none;
            }
            min-width: 100px;
            display: inline-block;
            border-radius: 0;
            border-color: transparent;
            border-top: none;
            border-bottom: none;
            background: #282e2c;
            color: #fefefe;
            line-height: 1;
            height: 100%;
            float: right;
            border-left: 1px solid #000;
            border-right: 1px solid #000;
            text-transform: uppercase;
          }
          *{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          width: 100%;
          height: 30px;
          display: block;
          border-top: 1px solid #000;
        }
        width: 100%;
        height: 100%;
        display: block;
        overflow: visible;
        float: left;
      }
      background: #282e2c;
      display: block;
      position: absolute;
      z-index: 2;
      overflow: visible;
      left: 50%;
      top: 25px;
      margin-bottom: 25px;
    }
    color: #fefefe;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    overflow: auto;
    top: 0;
  }
}

.elaine-dialog-back{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .5;
  z-index: 1;
  background: #000;
  display: none;
}


.elaine-slide-panel{
  &[es-side="left"]{
    border-right: 1px solid #000;
  }
  &[es-side="right"]{
    border-left: 1px solid #000;
  }
  &[es-side="top"]{
    border-bottom: 1px solid #000;
  }
  &[es-side="bottom"]{
    border-top: 1px solid #000;
  }
  background: #282e2c;
  overflow: hidden;
}
