.elaine-panel{
  .elaine-panel-navigation{
    &.e_form{
      .form-group{
        &.elaine-panel-navigation-view-select{
          .styled-select{
            &:after{
              top: 4px;
            }
            .form-control{
              height: 20px;
            }
            .chosen-container{
              .chosen-single{
                line-height: 20px;
              }
              height: 20px;
            }
            width: 100%;
          }
          width: 175px;
          border-right: 1px solid #000;
          height: 20px;
          margin-top: 5px;
        }
        &.elaine-panel-navigation-view-toggle{
          span{
            &.small{
              margin-top: 3px;
            }
          }
          label{
            width: auto;
            height: 20px;
            line-height: 25px;
            color: #fefefe;
          }
          height: 20px;
          padding: 0 10px;
          margin-top: 5px;
        }
        margin: 0;
        display: inline-block;
        float: left;
      }
    }
    display: block;
    width: 100%;
    height: 31px;
    border-bottom: 1px solid #000;
  }
  .elaine-panel-views{
    &.elaine-panel-views-with-navigation{
      height: calc( 100% - 31px );
    }
    &.elaine-panel-view-source{
      .elaine-panel-view-source-script{
        .ace_search{
          .ace_search_form{
            &.ace_nomatch{
              .ace_search_field{
                color: #731513;
              }
              outline: none;
            }
            .ace_search_field{
              &:focus{
                background: #797d7c;
              }
              background: #404644;
              color: #fefefe;
              border-color: #404644;
              border-radius: 0;
              outline: none;
            }
            .ace_searchbtn{
              &:hover{
                background-color: #9dc427;
              }
              background-color: #404644;
              border-color: #000;
              border-radius: 0;
              padding: 0 2px;
            }
            border-color: #404644;
            border-radius: 0;
            outline: none;
          }
          .ace_search_options{
            .ace_button{
              &:hover{
                background: #9dc427;
              }
              color: #fefefe;
              border: none;
              background: #404644;
            }
          }
          .ace_searchbtn_close{
            &:hover{
              background-color: #9dc427;
            }
            border-radius: 0;
            background-color: #404644;
          }
          background: #282e2c;
          color: #fefefe;
          border: none;
          border-radius: 0;
        }
        .ace_scroller {
          width: 100%;
        }
        .ace_scrollbar {
          display: none !important;
        }
        display: block;
        width: calc( 100% - 8px);
        height: 100%;
      }
      .elaine-panel-view-source-scroll{
        .elaine-panel-view-source-scroll-inner{
          position: absolute;
          top: 0;
          display: block;
          width: 8px;
          height: 50px;
          background: #585e5e;
        }
        position: absolute;
        right: 0;
        top: 0;
        width: 8px;
        height: 100%;
        background: #000;
      }
      height: calc( 100% - 2px );
      position: relative;
    }
    &.elaine-panel-view-message-preview{
      .elaine-message-preview-html{
        .elaine-message-preview-subject {
          &.no-edit{
            .elaine-message-preview-subject-group {
              .em-editor-buttons{
                .e_icn_btn{
                  &:last-of-type {
                    border-left: none;
                  }
                }
              }
              width: calc(100% - 36px);
            }
          }
          .elaine-message-preview-subject-group {
            label {
              height: 100%;
              line-height: 30px;
              width: 75px;
              color: #fefefe;
              padding-left: 10px;
            }
            span{
              &.form-control{
                width: calc( 100% - 78px);
              }
            }
            input {
              width: calc(100% - 80px);
            }
            width: calc(100% - 72px);
            display: inline-block;
            float: left;
            height: 30px;
            margin: 0;
          }
          .elaine-message-preview-subject-versions{
            .elaine-message-preview-subject-version{
              .elaine-message-preview-subject-version-condition{
                display: block;
                width: 100%;
                height: 20px;
                line-height: 20px;
                opacity: .6;
              }
              .elaine-message-preview-subject-version-text{
                display: block;
                width: 100%;
                height: 20px;
                line-height: 20px;
              }
              display: block;
              width: 100%;
              height: auto;
            }
            display: block;
            min-height: 50px;
            background: #404644;
            position: absolute;
            z-index: 1000;
            width: 100%;
            height: auto;
            border: 1px solid #000;
            top: 30px;
            padding: 5px 10px;
          }
          .em-editor-buttons{
            .e_icn_btn{
              &:last-of-type{
                border-left: 1px solid #000;
              }
              float: left;
              border-left: 1px solid #000;
            }
            float: left;
            display: inline-block;
          }
          width: 100%;
          height: 31px;
          display: block;
          overflow: visible;
          border-bottom: 1px solid #000;
        }
        .elaine-message-preview-html-inner{
          .elaine-message-preview-html-inner-container{
            .elaine-message-preview-html-stage{
              .elaine-message-preview-html-stage-inner{
                display: block;
                width: 100%;
                height: auto;
                position: relative;
                color: #000;
              }
              margin: 0 auto;
              background: #fefefe;
              overflow: hidden;
              -moz-transition: .75s linear all;
              -webkit-transition: .75s linear all;
              transition: .75s linear all;
            }
            display: block;
            width: auto;
            margin: 0 auto;
            padding: 20px 0;
          }
          display: block;
          width: 100%;
          height: calc( 100% - 65px );
          position: relative;
        }
        display:block;
        width: 100%;
        height: 100%;
        position: relative;
      }
      .elaine-panel-view-text{
        &.full{
          height: 100%;
        }
        .elaine-panel-view-text-preview{
          display: block;
          width: 100%;
          min-height: 100%;
          padding: 15px;
          border-radius: 0;
          word-wrap: normal;
        }
        display: block;
        width: 100%;
        height: calc( 100% - 36px );
        background: #fefefe;
      }
      .elaine-panel-view-attachments-sidebar{
        .elaine-panel-view-attachments-sidebar-attachment{
          &.active, &:hover{
            background: #9dc427;
          }
          display: block;
          color: #fefefe;
          padding: 0 10px;
          width: 100%;
          height: 30px;
          line-height: 30px;
          border-bottom: 1px solid #000;
        }
        display: block;
        width: 100%;
        height: auto;
        background: #282e2c;
      }
    }
    .elaine-panel-view-with-sidebar{
      .elaine-panel-view-with-sidebar-tabs{
        .nav{
          &.nav-tabs{
            >li{
              &.active{
                >a{
                  background-color: transparent;
                  color: #fefefe;
                  border: none;
                  border-bottom: 2px solid #9dc427;
                }
              }
              >a{
                &:hover{
                  background-color: transparent;
                  color: #fefefe;
                  border: none;
                  border-bottom: 2px solid #9dc427;
                }
                height: 100%;
                line-height: 30px;
                padding: 0 10px;
                background: transparent;
                border: none;
                color: #fefefe;
                text-decoration: none;
                cursor: pointer;
              }
              height: 100%;
              border-right: 1px solid #000;
            }
            border-bottom: 1px solid #000;
            height: 31px;
          }
        }
        .form-group-select{
          .styled-select{
            width: 100%;
          }
          width: 100%;
        }
        background: #282e2c;
      }
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .elaine-panel-view{
      display: inline-block;
      width: 100%;
      height: 100%;
      vertical-align: top;
      -moz-transition: .75s linear width;
      -webkit-transition: .75s linear width;
      transition: .75s linear width;
    }
    .elaine-panel-view-sidebar{
      display: inline-block;
      height: 100%;
      vertical-align: top;
      border-left: 2px solid #000;
      -moz-transition: .75s linear width;
      -webkit-transition: .75s linear width;
      transition: .75s linear width;
    }
    display: block;
    width: 100%;
    height: 100%;
  }
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
