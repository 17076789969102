//Listview
.elaine-list-view{
  *{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-collapse: collapse;
  }
  width: 100%;
  height: 100%;
}

.elaine-list-shade{
  .elaine-spinner{
    .e-floating-circles{
     background-color: #fefefe;
    }
    top: calc( 50% - 50px );
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba( 0, 0, 0, .25 );
  z-index: 1;
}

.listview_table {
  td,
  th {
    padding: 0;
  }
  thead {
    tr {
      th {
        &:hover {
          background: #404644;
        }
        &:last-child {
          border-right: none;
        }
        border-right: 1px solid #000;
        border-bottom: none;
        padding-left: 10px;
        font-weight: 100;
        cursor: pointer;
      }
      height: 30px;
    }
    border-bottom: 1px solid #000;
    background: #282e2c;
  }
  tbody {
    tr {
      td {
        border-top: none;
        height: 30px;
        padding-left: 10px;
      }
    }
    .dtRow, .childRow {
      &:hover {
        //        background: transparent;
        color: #9ec21a;
      }
      &.active {
        td {
          background: transparent;
          color: #9ec21a;
        }
      }
      cursor: pointer;
    }
  }
  color: #fefefe;
  height: 100%;
  width: 100%;
}

//Overwrite bootstrap icons with artegic icons
.dataTables_scrollHead {
  .dataTables_scrollHeadInner {
    table {
      &.dataTable {
        thead {
          th {
            &.sorting, &.sorting_asc, &.sorting_desc {
              background: #404644;
            }
            &.sorting:after {
              content: '';
              float: right;
              /* sort */
            }
            &.sorting_asc:after {
              content: ']';
              font-family: $iconFontFamily;
              color: #fefefe;
              opacity: 1;
              float: right;
              /* sort-by-attributes */
            }
            &.sorting_desc:after {
              content: '[';
              font-family: $iconFontFamily;
              color: #fefefe;
              opacity: 1;
              float: right;
              /* sort-by-attributes-alt */
            }
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_scrollHead {
    .listview_table {
      margin: 0 !important;
    }
  }
  .dataTables_scrollFoot {
    height: 30px;
  }
  .row {
    .right-foot {
      .dataTables_paginate {
        .paginate_button {
          &.first, &.last, &.next, &.previous {
            font-family: $iconFontFamily;
            cursor: pointer;
            color: #fefefe;
            font-size: 14px;
            padding: 7px;
            line-height: 30px;
          }
          &:hover:not( .disabled ) {
            background: #9dc427;
          }
          &.disabled {
            cursor: default;
            color: #6f6f6f;
          }
        }
        //style inputField
        .paginate_input {
          background-color: #474949;
          padding-right: 5px;
          color: #fefefe;
          text-align: right;
          border: none;
          width: 45%;
        }

        .firstPrev, .nextLast {
          span {
            &:first-of-type {
              border-right: 1px solid #000;
            }
          }
        }
        .paginate {
          .paginate_page {
            padding-left: 10px;
            padding-right: 10px;
          }
          .paginate_of {
            padding-left: 10px;
            padding-right: 10px;
          }
          width: auto;
          padding: 5px;
          padding-right: 10px;
          overflow: hidden;
          display: inline-block;
        }
        .divSelect {
          span {
            padding-right: 15px;
            padding-left: 7px;
            vertical-align: text-top;
          }
          > * {
            display: inline-block;
            height: 20px;
          }
          .styled-select {
            &:hover::before {
              background: #9dc427;
            }
            select {
              width: 55px;
              height: 20px;
              padding-left: 10px;
            }
          }

          padding: 5px;
        }
        .divConfig {
          &:hover {
            background: #9dc427;
          }
          cursor: pointer;
          padding-top: 8px;
          padding-left: 10px;
          padding-right: 10px;
        }
        > div {
          display: inline-block;
          height: 30px;

          border-left: 1px solid #000;
          vertical-align: middle;
          overflow: hidden;
        }

        //style selection/options
        .styled-select {
          &:hover {
            &:before {
              background: #474949;
            }
          }
          &:after {
            font-family: $iconFontFamily;
            content: '[';
            color: #fefefe;
            right: 4px;
            top: 2px;
            position: absolute;
            pointer-events: none;
          }
          &:before {
            content: '';
            right: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background: #474949;
            position: absolute;
            pointer-events: none;
            display: block;
            border-left: 1px solid #000;
          }
          select {
            display: inline-block;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          vertical-align: top;
          position: relative;
        }
        .selectLength {
          border: none;
          color: #fefefe;
          background-color: #474949;
        }
      }
      color: #fefefe;
      border-top: 1px solid #000000;
      text-align: right;
      //      padding-right: 25px;
    }
    .left-foot {
      border-top: 1px solid #000000;
      height: 30px;
    }
    .middle-foot {
      border-top: 1px solid #000000;
      height: 30px;
    }
  }
}

//Overwrite drag option
.ui-sortable-helper {
  &:not( .em-article-handle-template ){
    background: #9dc427;
    border-color: #9dc427;
    color: #fefefe;
  }
}

//Test for row details for dataTables
tr span.expand {
  width: 20px;
  height: 20px;
  background-image: url('http://www.datatables.net/release-datatables/examples/examples_support/details_open.png');
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

tr span.open {
  background-image: url('http://www.datatables.net/release-datatables/examples/examples_support/details_close.png');
}

div.expand-wrapper {
  white-space: nowrap;
}

//ListView Config
.elaine-list-config{
  .elaine-list-config-table{
    .elaine-list-config-table-header{
      .e_icn_btn{
        float: right;
        height: 29px;
      }
      width: 100%;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #000;
      padding-left: 10px;
    }
    .elaine-list-config-table-body{
      .elaine-list-config-table-item{
        &:nth-child(odd) {
          background: #1f2523;
        }
        &:nth-child(even) {
          background: #282e2c;
        }
        &:hover {
          background: #9dc427;
        }
        &.ui-sortable-selected {
          background-color: #9dc427 !important;
        }
        display: block;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
      }
      .elaine-list-config-zone{
        display: block;
        width: 100%;
        min-height: 400px;
      }
      display: block;
      width: 100%;
      height: calc( 100% - 30px );
    }
    display: block;
    width: 40%;
    height: 100%;
    float: left;
    border: 1px solid #000;
  }
  .elaine-list-config-table-control{
    .e_icn_btn{
      width: 30px;
      padding: 0 8px;
    }
    .control{
      display: table;
      margin: 0 auto;
    }
    .elaine-list-config-table-control-buttons{
      .e_icn_btn{
        float: left;
      }
      > span{
        display: block;
        width: 30px;
        height: 30px;
        float: left;
      }
      margin: 0 auto;
      width: auto;
      display: table;
    }
    display: block;
    width: 20%;
    height: 100%;
    float: left;
    position: relative;
    padding-top: 150px;
  }
  width: 100%;
  height: 450px;
  display: block;
  margin-bottom: 20px;
}

.table-striped {
  > tbody {
    tr{
      &:not( .dtRow ){
        > td{
          padding: 0;
        }
      }
    }
    > tr {
      &.dtRow {
        &.odd {
          + tr{
            table {
              tbody {
                > tr {
                  &:nth-child(even) {
                    background: #1f2523;
                  }
                  &:nth-child(odd) {
                    background: #282e2c;
                  }
                }
              }
              width: 100%;
            }
          }
          background: #1f2523;
        }
        &.even {
          + tr{
            table {
              tbody {
                > tr {
                  &:nth-child(even) {
                    background: #282e2c;
                  }
                  &:nth-child(odd) {
                    background: #1f2523;
                  }
                }
              }
              width: 100%;
            }
          }
          background: #282e2c;
        }
      }
    }
  }
}
