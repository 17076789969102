.ewe {
  .ewe-menu-bar {
    .ewe-menu-button {
      &[disabled]{
        opacity: .5;
        cursor: default;
      }
      &:hover{
        background: #9dc427;
      }
      &.right {
        float: right;
        border-left: 1px solid #000;
        border-right: none;
      }
      .e_icn {
        float: left;
        width: 30px;
        height: 100%;
        display: block;
        line-height: 30px;
      }
      .ewe-menu-button-text{
        text-transform: uppercase;
      }
      .ewe-menu-button-title {
        float: left;
        width: auto;
        height: 100%;
        display: block;
        padding: 0 10px;
      }
      display: inline-block;
      width: auto;
      height: 100%;
      line-height: 30px;
      padding: 0 10px 0 10px;
      border-right: 1px solid #000;
      cursor: pointer;
      vertical-align: top;
    }
    .ewe-menu-bar-left {
      .ewe-menu-title {
        display: inline-block;
        width: auto;
        max-width: 200px;
        height: 100%;
        line-height: 30px;
        padding: 0 10px;
        border-right: 1px solid #000;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
        white-space: nowrap;
      }
      width: 35%;
      height: 100%;
      display: block;
      float: left;
    }
    .ewe-menu-bar-right {
      .ewe-menu-bar-size-control {
        .ewe-menu-bar-size-control-button {
          &:hover {
            background: #9dc427;
          }
          &[disabled] {
            opacity: .5;
            cursor: default;
          }
          display: block;
          float: left;
          width: 30px;
          height: 100%;
          border-left: 1px solid #000;
          border-right: 1px solid #000;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
        }
        .ewe-menu-bar-size-control-view {
          span{
            background:transparent;
            padding: 2px 5px;
          }
          display: block;
          float: left;
          width: 50px;
          height: 100%;
          text-align: center;
          line-height: 30px;
        }
        width: auto;
        display: inline-block;
        height: 100%;
        line-height: 30px;
        float: left;
      }
      .ewe-menu-running-notice{
        float: right;
        display: inline-block;
        width: auto;
        height: 100%;
        line-height: 30px;
        padding: 0 10px;
        border-left: 1px solid #000;
        color: #826200;
        background: #FFEAA8;
      }
      width: 65%;
      height: 100%;
      display: block;
      float: left;
    }
    width: 100%;
    height: 30px;
    display: block;
    color: #fefefe;
  }
  .ewe-sidebar-panel-group {
    .ewe-sidebar-panel-group-header {
      h5 {
        margin: 10px
      }
      width: 100%;
      display: block;
      height: auto;
      color: #fefefe;
      overflow: hidden
    }
    .ewe-sidebar-panel-group-body {
      .ewe-sidebar-panel {
        .ewe-sidebar-panel-left {
          padding: 0 10px;
          width: 40px;
          float: left;
          height: 100%;
          overflow: hidden;
          display: block;
          line-height: 40px;
        }
        .ewe-sidebar-panel-right {
          span {
            font-size: 14px;
            line-height: 30px;
            height: 30px;
          }
          width: calc(100% - 40px);
          float: left;
          height: 100%;
          overflow: hidden;
          display: block;
          line-height: 40px;
        }
        &:last-of-type{
          border-bottom: 1px solid #000;
        }
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        background: #3a403f;
        width: 100%;
        height: 40px;
        border-top: 1px solid #000;
      }
      width: 100%;
      display: block;
      height: auto;
      overflow: hidden;
      color: #fefefe;
    }
    width: 100%;
    display: block;
    overflow: hidden;
    border-bottom: 6px solid #000;
  }
  .ewe-view-inner-container {
    .jspVerticalBar, .jspHorizontalBar {
      z-index: 100000;
    }
    .ewe-view-inner {
      &.grabbed{
        cursor: grabbing;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
      }
      &.ewe-zooming{
        svg{
          display: none;
        }
      }
      &.ewe-view-zoom-90{
        .ewe-panel {
          .ewe-panel-left {
            width: 54px;
            height: 54px;
            line-height: 54px;
            .e_icn{
              font-size: 20px;
            }
          }
          .ewe-panel-right {
            .ewe-panel-show {
              .ewe-panel-show-left {
                .ewe-panel-show-title {
                  padding-top: 9px;
                  font-size: 12px;
                }
                width: 81px;
              }
              .ewe-panel-show-right {
                .ewe-panel-show-button {
                  .e_icn{
                    font-size: 13px;
                  }
                  height: 27px;
                  line-height: 27px;
                }
                width: 27px;
              }
            }
            width: 108px;
            height: 54px;
          }
          width: 162px;
          height: 54px;
        }
        .ewe-panel-branch-container {
          .ewe-panel-branch {
            .ewe-panel-branch-left {
              width: 54px;
              line-height: 54px;
            }
            .ewe-panel-branch-right {
              .ewe-panel-branch-show {
                .ewe-panel-branch-show-left {
                  .ewe-panel-branch-show-title {
                    font-size: 14px;
                    line-height: 54px;
                    height: 54px;
                  }
                  width: 81px;
                }
                .ewe-panel-branch-show-right {
                  .ewe-panel-branch-show-button {
                    .e_icn{
                      font-size: 13px;
                    }
                    height: 27px;
                    line-height: 27px;
                  }
                  width: 27px;
                }
              }
              width: 108px;
            }
            height: 54px;
          }
        }
        background-size: 27px 27px;
      }
      &.ewe-view-zoom-80{
        .ewe-panel {
          .ewe-panel-left {
            width: 48px;
            height: 48px;
            line-height: 48px;
            .e_icn{
              font-size: 18px;
            }
          }
          .ewe-panel-right {
            .ewe-panel-show {
              .ewe-panel-show-left {
                .ewe-panel-show-title {
                  padding-top: 8px;
                  font-size: 12px;
                }
                width: 72px;
              }
              .ewe-panel-show-right {
                .ewe-panel-show-button {
                  .e_icn{
                    font-size: 12px;
                  }
                  height: 24px;
                  line-height: 24px;
                }
                width: 24px;
              }
            }
            width: 96px;
            height: 48px;
          }
          width: 144px;
          height: 48px;
        }
        .ewe-panel-branch-container {
          .ewe-panel-branch {
            .ewe-panel-branch-left {
              width: 48px;
              line-height: 48px;
            }
            .ewe-panel-branch-right {
              .ewe-panel-branch-show {
                .ewe-panel-branch-show-left {
                  .ewe-panel-branch-show-title {
                    height: 48px;
                    line-height: 48px;
                    font-size: 12px;
                  }
                  width: 72px;
                }
                .ewe-panel-branch-show-right {
                  .ewe-panel-branch-show-button {
                    .e_icn{
                      font-size: 12px;
                    }
                    height: 24px;
                    line-height: 24px;
                  }
                  width: 24px;
                }
              }
              width: 96px;
            }
            height: 48px;
          }
        }
        background-size: 24px 24px;
      }
      &.ewe-view-zoom-70{
        .ewe-panel {
          .ewe-panel-left {
            width: 42px;
            height: 42px;
            line-height: 42px;
            .e_icn{
              font-size: 15px;
            }
          }
          .ewe-panel-right {
            .ewe-panel-show {
              .ewe-panel-show-left {
                .ewe-panel-show-title {
                  padding-top: 7px;
                  font-size: 12px;
                }
                width: 63px;
              }
              .ewe-panel-show-right {
                .ewe-panel-show-button {
                  .e_icn{
                    font-size: 11px;
                  }
                  height: 21px;
                  line-height: 21px;
                }
                width: 21px;
              }
            }
            width: 84px;
            height: 42px;
          }
          width: 126px;
          height: 42px;
        }
        .ewe-panel-branch-container {
          .ewe-panel-branch {
            .ewe-panel-branch-left {
              width: 42px;
              line-height: 42px;
            }
            .ewe-panel-branch-right {
              .ewe-panel-branch-show {
                .ewe-panel-branch-show-left {
                  .ewe-panel-branch-show-title {
                    font-size: 12px;
                    line-height: 42px;
                    height: 42px;
                  }
                  width: 63px;
                }
                .ewe-panel-branch-show-right {
                  .ewe-panel-branch-show-button {
                    .e_icn{
                      font-size: 11px;
                    }
                    height: 21px;
                    line-height: 21px;
                  }
                  width: 21px;
                }
              }
              width: 84px;
            }
            height: 42px;
          }
        }
        background-size: 21px 21px;
      }
      &.ewe-view-zoom-60{
        .ewe-panel {
          .ewe-panel-left {
            width: 36px;
            height: 36px;
            line-height: 36px;
            .e_icn{
              font-size: 13px;
            }
          }
          .ewe-panel-right {
            .ewe-panel-show {
              .ewe-panel-show-left {
                .ewe-panel-show-title {
                  padding-top: 6px;
                  font-size: 10px;
                }
                width: 54px;
              }
              .ewe-panel-show-right {
                .ewe-panel-show-button {
                  .e_icn{
                    font-size: 10px;
                  }
                  height: 18px;
                  line-height: 18px;
                }
                width: 18px;
              }
            }
            width: 72px;
            height: 36px;
          }
          width: 108px;
          height: 36px;
        }
        .ewe-panel-branch-container {
          .ewe-panel-branch {
            .ewe-panel-branch-left {
              width: 36px;
              line-height: 36px;
            }
            .ewe-panel-branch-right {
              .ewe-panel-branch-show {
                .ewe-panel-branch-show-left {
                  .ewe-panel-branch-show-title {
                    font-size: 10px;
                    line-height: 36px;
                    height: 36px;
                  }
                  width: 54px;
                }
                .ewe-panel-branch-show-right {
                  .ewe-panel-branch-show-button {
                    .e_icn{
                      font-size: 10px;
                    }
                    height: 18px;
                    line-height: 18px;
                  }
                  width: 18px;
                }
              }
              width: 72px;
            }
            height: 36px;
          }
        }
        background-size: 18px 18px;
      }
      &.ewe-view-zoom-50{
        .ewe-panel {
          .ewe-panel-left {
            width: 30px;
            height: 30px;
            line-height: 30px;
            .e_icn{
              font-size: 10.5px;
            }
          }
          .ewe-panel-right {
            .ewe-panel-show {
              .ewe-panel-show-left {
                .ewe-panel-show-title {
                  padding-top: 5px;
                  font-size: 10px;
                }
                width: 45px;
              }
              .ewe-panel-show-right {
                .ewe-panel-show-button {
                  .e_icn{
                    font-size: 10px;
                  }
                  height: 15px;
                  line-height: 15px;
                }
                width: 15px;
              }
            }
            width: 60px;
            height: 30px;
          }
          width: 90px;
          height: 30px;
        }
        .ewe-panel-branch-container {
          .ewe-panel-branch {
            .ewe-panel-branch-left {
              width: 30px;
              line-height: 30px;
            }
            .ewe-panel-branch-right {
              .ewe-panel-branch-show {
                .ewe-panel-branch-show-left {
                  .ewe-panel-branch-show-title {
                    font-size: 10px;
                    line-height: 30px;
                    height: 30px;
                  }
                  width: 45px;
                }
                .ewe-panel-branch-show-right {
                  .ewe-panel-branch-show-button {
                    .e_icn{
                      font-size: 10px;
                    }
                    height: 15px;
                    line-height: 15px;
                  }
                  width: 15px;
                }
              }
              width: 60px;
            }
            height: 30px;
          }
        }
        background-size: 15px 15px;
      }
      .ewe-panel-wrapper {
        &.ewe-panel-active-menu{
          z-index: 1005;
        }
        position: absolute;
        z-index: 1000;
      }
      position: relative;
      width: 3000px;
      height: 3000px;
      background: #000;
      background-image: url(/gui/img/grid.gif);
      background-position: left top;
      background-repeat: repeat;
      background-size: 30px 30px;
    }
    width: 100%;
    height: 100%;
  }
  .ewe-panel-wrapper {

    &.active{
      z-index: 10000 !important;
    }
    &.ewe-panel-passive {
      .ewe-panel-left,.ewe-panel-right,.ewe-panel-branch-container{
        opacity: .5;
      }
    }
    &.ewe-panel-dont-drop {
      .ewe-panel {
        .ewe-panel-left {
          background: #aa2921;
        }
        .ewe-panel-right {
          background: #aa2921;
        }
      }
      .ewe-panel-branch-container{
        .ewe-panel-branch{
          background: #aa2921;
        }
      }
      opacity: .5;
    }

    .ewe-panel-circle{
      &.left{
        left: -5px;
      }
      &.right{
        right: -5px;
      }
      width: 10px;
      height: 10px;
      background: #fefefe;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      position: absolute;
      top: calc( 50% - 5px );
    }

    .ewe-panel {
      .ewe-panel-left {
        .ewe-panel-left-colored{
          .ewe-panel-left-colored-split{
            .ewe-panel-left-colored-top{
              display: block;
              width: 100%;
              height: 50%;
              text-align: center;
            }
            .ewe-panel-left-colored-bottom{
              display: block;
              width: 100%;
              height: 50%;
            }
            display: block;
            width: 100%;
            height: 100%;
          }
          display: block;
          width: 100%;
          height: 100%;
        }
        .e_icn{
          font-size: 24px;
        }
        width: 60px;
        height: 60px;
        line-height: 60px;
        float: left;
        overflow: hidden;
        text-align: center;
        background: #3a403f;
        cursor: grab;
        cursor: -webkit-grab;
        cursor: -moz-grab;
      }
      .ewe-panel-right {
        .ewe-panel-show {
          .ewe-panel-show-left {
            .ewe-panel-show-title {
              span{
                display: block;
                width: 100%;
                overflow: hidden;
                text-transform: uppercase;
                transition: background 0s ease 0s, border-color 0s ease 0s, color 0.3s ease-in-out 0s;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .ewe-panel-show-description {
                width: 100%;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #979a99;
              }
              font-size: 14px;
              width: 100%;
              height: 100%;
              display: block;
              overflow: hidden;
              padding-top: 10px;
            }

            width: 90px;
            height: 100%;
            display: block;
            overflow: hidden;
            float: left;
            cursor: grab;
            cursor: -webkit-grab;
            cursor: -moz-grab;
          }
          .ewe-panel-show-right {
            .ewe-panel-show-button {
              &.disabled {
                span{
                  cursor: default;
                }
                opacity: .5;
                cursor: default;
              }
              &:hover {
                background: #9dc427;
                cursor: pointer;
              }
              width: 100%;
              height: 30px;
              display: block;
              overflow: hidden;
              line-height: 30px;
              text-align: center;
            }
            width: 30px;
            height: 100%;
            display: block;
            overflow: hidden;
            float: left;
          }
          width: 100%;
          height: 100%;
          display: block;
          overflow: hidden;
        }
        width: 120px;
        height: 60px;
        float: left;
        overflow: hidden;
        background: #3a403f;
      }
      width: 180px;
      height: 60px;
      display: block;
      overflow: visible;
      position: relative;
    }
    .ewe-panel-branch-container {
      .ewe-panel-branch {
        .ewe-panel-branch-left {
          width: 60px;
          height: 100%;
          display: block;
          float: left;
          line-height: 60px;
          text-align: center;
        }
        .ewe-panel-branch-right {
          .ewe-panel-branch-show {
            .ewe-panel-branch-show-left {
              .ewe-panel-branch-show-title {
                font-size: 14px;
                width: 100%;
                line-height: 60px;
                height: 60px;
                display: block;
                overflow: hidden;
                text-transform: uppercase;
              }
              width: 90px;
              height: 100%;
              display: block;
              overflow: hidden;
              float: left;
            }
            .ewe-panel-branch-show-right {
              .ewe-panel-branch-show-button {
                &.disabled {
                  span{
                    cursor: default;
                  }
                  opacity: .5;
                  cursor: default;
                }
                &:hover {
                  background: #9dc427;
                  cursor: pointer;
                }
                width: 100%;
                height: 30px;
                display: block;
                overflow: hidden;
                line-height: 30px;
                text-align: center;
              }
              width: 30px;
              height: 100%;
              display: block;
              overflow: hidden;
              float: left;
            }
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
          }
          width: 120px;
          height: 100%;
          display: block;
          float: left;
        }
        width: 100%;
        height: 60px;
        display: block;
        border-top: 1px solid #000;
        background: #3a403f;
        overflow: visible;
        position: relative;
      }
      width: 100%;
      height: auto;
      display: block;
      overflow: visible;
    }
    width: auto;
    height: auto;
    display: block;
    overflow: visible;
    color: #fefefe;
  }
  .ewe-panel-menu {
    .ewe-panel-menu-item {
      &[disabled]{
        cursor: default;
        opacity: .5;
      }
      &:hover {
        & > a {
          span {
            color: #9dc427;
          }
          color: #9dc427;
        }
      }
      .ewe-panel-menu-item-second-list{
        &.identifier{
          min-width: 120px;
        }
        &.open{
          display: block;
        }
        li{
          &[disabled]{
            opacity: .5;
            cursor: default;
          }
          white-space: nowrap;

        }
        position: absolute;
        left: 100%;
        top: -1px;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        background: #282e2c;
        border: 1px solid #000;
      }
      a{
        span{
          &.e_icn{
            position: absolute;
            top: 8px;
            right: 5px;
            width: auto;
          }
          width: 100%;
          height: 100%;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        padding: 0 5px;
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
      }
      position: relative;
      width: 100%;
      height: 30px;
      line-height: 30px;
      overflow: visible;
      display: block;
      cursor: pointer;
    }
    position: absolute;
    width: 130px;
    height: auto;
    overflow: visible;
    z-index: 1000;
    margin: 0;
    padding: 0;
    background: #282e2c;
    border-left: 1px solid #000;
    top: 0;
    left: 100%;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    box-shadow: 0 6px 12px rgba(0,0,0,0.175)
  }
  .ewe-panel-endpoint {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: background 0.3s ease-in-out 0s;
    z-index: 1001;
  }
  
  #ewe-logo {

    display: flex !important;
    justify-content: center;
    align-items: center;

    .logo {
      width: 115px;
    }
  }

  a {
    text-decoration: none;
    color: #fefefe;
  }

  .jtk-connector{
    &.ewe-goto-path{
      path{
        stroke-width: 2;
        stroke-dasharray: 5;
        stroke-dashoffset: 0;
      }
    }
  }
  .jtk-overlay{
    background-color: #282d2c;
    border: 1px solid #000;
    color: #979a99 !important;
    display: inline-block;
    max-width: 80px;
    overflow: hidden;
    padding: 2px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ewe-hotpath-show{
    .ewe-hotpath-show-top{
      .ewe-hotpath-show-top-left{
        width: 65%;
        display: block;
        float: left;
        height: 100%;
        padding: 0 10px;
      }
      .switch-item{
        background: #009CC9;
        border-color: #009CC9;
      }
      .ewe-hotpath-show-top-right{
        label{
          .e-switch-label{
            &.left{
              text-align: right;
            }
            width: 50px;
            vertical-align: super;
            display: inline-block;
          }
          width: 100%;
          height: 50%;
          display: block;
          line-height: 20px;
          margin: 0;
        }
        width: 35%;
        display: block;
        float: left;
        height: 100%;
      }
      color: #fefefe;
      width: 100%;
      height: 40px;
      display: block;
      border-bottom: 1px solid #000;
    }
    .ewe-hotpath-show-bottom{
      .ewe-hotpath-show-bottom-top{
        .ewe-hotpath-show-bottom-legend{
          .ewe-hotpath-legend-item{
            display: block;
            margin: auto;
          }
          transform: rotate( 90deg );
          display: inline-block;
          position: absolute;
          top: -155px;
          left: 195px;
        }
        width: 100%;
        height: 70px;
        display: block;
        padding: 0 25px;
        position: relative;
      }
      .ewe-hotpath-show-bottom-bottom{
        .ewe-hotpath-show-bottom-legend-left{
          display: block;
          width: 30px;
          height: 100%;
          float: left;
          text-align: center;
          line-height: 25px;
        }
        .ewe-hotpath-show-bottom-legend-middle{
          float: left;
          width: calc( 100% - 67px );
          height: 14px;
          border: 1px solid #fefefe;
          border-top: none;
          margin-top: 4px;
          display: block;
        }
        .ewe-hotpath-show-bottom-legend-right{
          float: left;
          width: 33px;
          height: 100%;
          display: block;
          text-align: right;
          line-height: 25px;
        }
        color: #fefefe;
        width: 100%;
        height: 30px;
        display: block;
      }
      width: 100%;
      height: 100px;
      display: block;
    }
    width: 450px;
    height: auto;
    overflow: hidden;
    display: block;
    border: 1px solid #000;
    position: absolute;
    top: 0;
    right: 10px;
    background: #282e2c;
    z-index: 10009;
  }
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.ewe-random-control{
  .ewe-random-control-inner{
    .ewe-random-control-item{
      button{
        &:not([disabled]):hover{
          background: #9dc427;
        }
        &[disabled]{
          opacity: .5;
          cursor: default;
        }
        height: 25px;
        margin: 0;
        line-height: 25px;
      }
      .ewe-random-control-item-id{
        width: 10%;
        height: 100%;
        display: block;
        float: left;
        line-height: 30px;
        text-align: left;
        padding: 0 5px;
      }
      .ewe-random-control-item-percent{
        input{
          width: 100%;
          height: 25px;
          background: #404644;
          border-color: #404644;
          margin-top: 2px;
        }
        width: 71%;
        height: 100%;
        display: block;
        float: left;
        line-height: 30px;
        text-align: center;
      }
      .ewe-random-control-item-action{
        width: calc( 20% - 5px );
        height: 100%;
        display: block;
        float: left;
        line-height: 25px;
        text-align: center;
        padding-top: 2px;
      }
      width:  calc( 100% - 10px );
      height: 31px;
      display: block;
      overflow: hidden;
      border: 1px solid #000;
      background: #282e2c;
      margin: 5px;
    }
    width: 100%;
    height: calc( 100% - 40px );
    padding: 5px;
    border: 1px solid #000;
    background: #404644;
    display: block;
  }
  .ewe-random-control-buttons{
    padding: 0;
    width: 100%;
    height: 30px;
    display: block;
    margin-top: 10px;
  }
  &.response{
    .ewe-random-control-inner{
      height: 100%;
    }
    height: 200px;
  }
  &.mailing{
    .ewe-random-control-inner{
      .ewe-random-control-item-percent{
        .exit-name{
          width: 45%;
          height: 30px;
          line-height: 30px;
          display: inline-block;
        }
        .multiple{
          input{
            &:checked,&:not(:checked){
              + span{
                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 9px;
                  width: 12px;
                  height: 12px;
                  background: #404644;
                  border-radius: 0px;
                  box-shadow: inset 0 1px 3px #404644
                }
              }
            }
            /* checkbox mark  */
            &:checked{
              + span{
                &:after {
                  font-family: $iconFontFamily;
                  content: ',';
                  position: absolute;
                  top: 12px;
                  left: 2px;
                  font-size: 8px;
                  line-height: 0.8;
                  color: #fefefe;
                  transition: all .2s;
                  border-radius: 0;
                  outline: none;
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }
            /* checked mark aspect changes */
            &:not(:checked){
              + span{
                &:after{
                  opacity: 0;
                  transform: scale(0);
                }
              }
            }
            display: none;
            float: left;
            margin: 2px 5px 0 0;
          }
          span{
            padding-left: 20px;
          }
          position: relative;
          width: 45%;
          height: 30px;
          line-height: 30px;
          display: inline-block;
        }
        text-align: left;
      }
      height: 100%;
    }
    height: 200px;
  }
  width: 100%;
  height: 250px;
  display: block;
  overflow: hidden;
}

.webui-popover-ewe{
  .webui-popover-title{
    display: none;
  }
  .webui-popover-content{
    .ewe-popover-content{
      .ewe-popover-content-header{
        h4{
          margin: 0;
        }
        .ewe-popover-content-header-type{
          text-transform: uppercase;
        }
        padding: 5px;
        width: 100%;
        height: 45px;
        display: block;
        border-bottom: 1px solid #000;
      }
      .ewe-popover-content-middle{
        .ewe-popover-content-middle-top{
          .ewe-popover-content-middle-top-left{
            width: 50%;
            height: 100%;
            display: block;
            float: left;
            border-right: 1px solid #000;
          }
          .ewe-popover-content-middle-top-right{
            width: 50%;
            height: 100%;
            display: block;
            float: left;
            padding: 0 5px;
          }
          .ewe-popover-content-middle-main-value{
            .value{
              font-size: 24px;
              width: 100%;
              display: block;
            }
            span{
              width: 100%;
              display: block;
            }
          }
          padding: 5px;
          border-bottom: 1px solid #000;
          overflow: hidden;
        }
        .ewe-popover-content-middle-bottom{
          .header{
            width: 100%;
            display: block;
          }
          padding: 5px;
        }
        width: 100%;
        height: 175px;
        display: block;
      }
      .ewe-popover-content-footer{
        .margin-bottom{
          margin-bottom: 5px;
        }

        label{
          .switch-item{
            float: right;
          }
          width: 100%;
          height: 20px;
          margin: 0;
        }
        padding: 5px;
        width: 100%;
        height: auto;
        display: block;
        border-top: 1px solid #000;
      }
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
    }
    padding: 0;
    color: #fefefe;
  }
  border-radius: 0;
  background: #3a403f;
  border-color: #000;
}
