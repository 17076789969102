//$preview-tab-forms__A0: themed('base-white')!default;
$preview-tab-forms__A0: white;
$preview-tab-forms__A1: themed('base:accent--text-color')!default;
$preview-tab-forms__A2: themed('info--color')!default;
$preview-tab-forms__A3: themed('error--color')!default;



.me__preview-tab-forms {
  color: $preview-tab-forms__A0;

  .icon-check-circle{
    color: $preview-tab-forms__A1;
  }
  .icon-angle-up{
    font-size: 10px;
  }
  .icon-angle-down{
    font-size: 10px;
  }
  .icon-timer{
    color: $preview-tab-forms__A2;
  }
  .icon-close-circle{
    color: $preview-tab-forms__A3;
  }

  &__comment-body{
    margin-left: 8px;
  }

  &__single-approval-body{
    margin-left: 8px;
  }

  &__version-row{
    margin-top: 15px !important;
    margin-left: 8px !important;

    label{
      max-width:100px;
      position:relative !important;
      float:left;
    }
  }

  &__version-body{
    width:100px;
    display: inline-block;
    margin-left:10px;
  }
  &__emptyData{
    margin-left: 8px;
  }

  &__table-files{
    .e__table__table-body{
      //max-height: 120px;
      overflow:hidden;
      display: block;
    }
  }

  .feedbackFiles{
    color: #9dc427;
    &__odd{
      background: map-get($mappedPalette, 'base-grey-02') !important;
      border-right: 1px solid #000 !important;
    }
    &__even{
      background: map-get($mappedPalette, 'base-grey-03') !important;
      border-right: 1px solid #000 !important;
    }
  }
  .e__table-cell{
    &.icon-close::before{
      color:red;
    }
  }
  .personalization-hint {
    background: #282e2c;
    padding: 5px;
  }
  &__spinner{
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__label {
    min-width: 65px;
  }

}
