@import 'content-transfer';
@import 'create-approval';
@import 'preview_dispatch';
@import 'abort-approval';
@import "message-approve";
@import "message-sending-and-planing";
@import "user-edit-properties";

$md-dialog__A0: themed('base--bg-color') !default;
$md-dialog__B0: themed('frame--bg-color') !default;
$md-dialog__C0: themed('frame--bg-color') !default;
$md-dialog__D0: themed('base:accent--text-color') !default;
$md-dialog__E0: themed('frame--bg-color') !default;
$md-dialog__F0: themed('error--color') !default;
$md-dialog__G0: themed('frame--bg-color') !default;
$md-dialog__H0: themed('frame--bg-color') !default;
$md-dialog__H1: themed('frame--bg-color') !default;
$md-dialog__J0: themed('element-action:hover-cancel--bg-color') !default;

md-dialog {
  background-color: $md-dialog__A0;
  min-width: 200px;
  height: 500px;
  display: flex;
  flex-direction: row;
  overflow: visible;

  &.me__dialog {

  }

  &.md-transition-in, &.md-transition-out {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .dialog__drag-handle {
    cursor: move;
  }

  .dialog__right-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 300px;

    //Header just in right area: @todo: diference betwen xaf elaine?
    .dialog__header {
      border-bottom: 1px solid $md-dialog__C0;
      height: 30px;
      min-height: 30px;
      width: 100%;
      display: flex;

      .dialog__title {
        flex-grow: 1;
        color: $md-dialog__D0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 30px;
        padding-left: 5px;
      }

      .dialog__header-close-button {
        width: 30px;
        height: 30px;
        font-size: 16px;
        line-height: 33px;
        text-align: center;
        border-left: 1px solid $md-dialog__E0;
        cursor: pointer;

        &:hover {
          background-color: $md-dialog__F0;
        }
      }
    }

    .dialog__form {
      display: flex;
      width: 100%;
      flex: 1 0 0;
      min-height: calc(100% - 60px);
    }

    .dialog__form-content {
      flex: 1 1 auto;
      display: flex;
    }

    .dialog__form-content__inner {
      width: 100%;
      position: relative;
    }

    .dialog__form-loader {
      position: absolute;
      top: 30px;
      bottom: 30px;
      left: 90px;
      right: 0;
    }

    .dialog__footer {
      border-top: 1px solid $md-dialog__G0;
      height: 30px;
      min-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-end;
      width: 100%;
      overflow: hidden;
      background: $eColorBase;

      > .me__button {
        font-size: 14px;
        text-transform: uppercase;
        border-right: 1px solid $eColorDark;
        border-left: 1px solid $eColorDark;

        &:only-child {
          border-right: none;
        }

        &:last-child {
          margin-left: 30px;
          border-right: none;
        }
      }
    }
  }
}

.dynamicHeight{
  height: auto !important;
  .elaine-dialog-right {
    border-left: 1px solid #000;
  }
}
//Tabs & frames animations
.dialog__frames-container {
  //
}

.chosen-loading-spinner{
  .ge__loading-spinner__show{
    margin-right: 35px;
    margin-top: -3px;
  }
}

.dialog__frame {
  &.ng-animate {
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.dialog__frame.ng-leave.ng-leave-active,
.dialog__frame.ng-enter {
  opacity: 0;
}

.dialog__frame.ng-leave,
.dialog__frame.ng-enter.ng-enter-active {
  opacity: 1;
}

