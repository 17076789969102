.ejm-sidebar-list{
  .ejm-sidebar-list-item{
    &[disabled]{
      opacity: .5;
      cursor: default;
    }
    .ejm-sidebar-list-item-text{
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    background: #3a403f;
    list-style: none;
  }
  display: block;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ejmDragHelper{
  display: block;
  width: 30px;
  height: 30px;
  background: #404644;
}

.ejm-view-inner-container{
  .jspVerticalBar,.jspHorizontalBar{
    z-index: 5000;
  }
  .ejm-view-inner{
    .ejm-corner-stone{
      &.bottom{
        bottom: 0;
        border-top: 4px solid #282E2C;
      }
      &.top{
        top: 0;
        border-bottom: 4px solid #282E2C;
      }
      display: block;
      width: 30px;
      height: 30px;
      background: #404644;
      border-right: 4px solid #282E2C;
      position: absolute;
      left: 0;
      z-index: 3000;
    }
    .ejm-panel-menu{
      &.campaign{
        .ejm-panel-menu-arrow{
          &:after{

          }
          border-left-width: 0;
          border-right-color: #9dc427;
          top: 15px;
          left: -1px;
        }
        top: 16px;
        left: 30px;
      }
      &.lane{
        .ejm-panel-menu-arrow{
          &:after{

          }
          border-left-width: 0;
          border-right-color: #9dc427;
          top: 30px;
          left: -11px;
        }
        top: 40px;
        left: 30px;
      }
      &.step{
        .ejm-panel-menu-arrow{
          &:after{

          }
          border-top-width: 0;
          border-bottom-color: #9dc427;
          top: -1px;
          left: 0;
        }
        top: 30px;
        left: 20px;
      }
      .ejm-panel-menu-item{
        &:hover:not([disabled]){
          a{
            color: #9dc427;
          }
        }
        &[disabled]{
          opacity: .5;
          cursor: default;
        }
        a{
          span{
            &.e_icn{
              position: absolute;
              top: 8px;
              right: 5px;
            }
          }
          padding: 0 5px;
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          text-transform: none;
        }
        position: relative;
        width: 100%;
        height: 30px;
        line-height: 30px;
        overflow: visible;
        display: block;
        cursor: pointer;
      }
      .ejm-panel-menu-arrow{
        &:after{
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          content: " ";
          left: 1px;
          bottom: -10px;
          border-width: 10px;
          border-right-width: 0;
          border-left-color: #fefefe;
        }
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-width: 11px;
        border-color: transparent;
        border-style: solid;
        margin-top: -11px;
      }
      position: absolute;
      width: 130px;
      height: auto;
      overflow: visible;
      z-index: 1000;
      margin: 0;
      padding: 0;
      background: #3a403f;
      top: 0;
      left: 100%;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: $boxShadow;
      box-shadow: $boxShadow
    }
    .ejm-lanes{
      .ejm-lane{
        &:last-of-type{
          .ejm-lane-header, .ejm-lane-body{
            border-bottom: none;
          }
        }
        .ejm-lane-header{
          .ejm-lane-header-handle{
            font-size: 24px;
            width: 100%;
            display: block;
            text-align: center;
            line-height: 10px;
            height: 25px;
            cursor: grab;
            cursor: -webkit-grab;
            cursor: -moz-grab;
          }
          .ejm-lane-header-span{
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            margin-left: 15px;
            position: absolute;
            top: 50%;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            max-width: 175px;
          }
          display: inline-block;
          width: 30px;
          border-right: 4px solid #282E2C;
          border-bottom: 4px solid #282E2C;
          text-transform: uppercase;
          background: #404644;
          position: absolute;
          height: 200px;
          z-index: 2000;
        }
        .ejm-lane-body{
          display: inline-block;
          width: 100%;
          height: 100%;
          background: #000;
          border-bottom: 4px dashed #282E2C;
          vertical-align: top;
        }
        display: block;
        width: 100%;
        height: 200px;
      }
      border-collapse: collapse;
      position: relative;
      top: 30px;
    }
    .ejm-campaigns{
      .ejm-campaign{
        &.active{
          .ejm-campaign-inner{
            background: #009CC9;
          }
          z-index: 999 !important;
        }
        &.deleted{
          .ejm-campaign-inner, & > span {
            opacity: .5;
          }
        }
        span{
          display: block;
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ejm-campaign-inner{
          width: 30px;
          height: 30px;
          background: #404644;
        }
        position: absolute;
        z-index: 1000;
      }
      &.grabbed{
        cursor: grabbing;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
      }
      position: absolute;
      display: block;
      width: 100%;
      height: auto;
      top: 30px;
      left: 0;
      cursor: grab;
      cursor: -webkit-grab;
      cursor: -moz-grab;
    }
    .ejm-steps{
      .ejm-steps-header{
        display:block;
        width: 100%;
        height: 30px;
        position: absolute;
        top: 0;
        background: #404644;
        border-bottom: 4px solid #282E2C;
        z-index: 1000;
      }
      .ejm-steps-footer{
        .ejm-steps-foot{
          display: block;
          width: auto;
          height: 30px;
          line-height: 30px;
          position: absolute;
        }
        display:block;
        width: 100%;
        height: 30px;
        position: absolute;
        background: #404644;
        border-top: 4px solid #282E2C;
        z-index: 1000;
      }
      .ejm-step{
        &.ghost{
          opacity: .5;
        }
        .ejm-step-header{
          span{
            &.text{
              max-width: 125px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            display: inline-block;
            height: 100%;
            width: auto;
            vertical-align: top;
          }
          position: absolute;
          display: block;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
          top: 0;
          left: 0;
          padding: 0 10px;
          cursor: pointer;
        }
        position: absolute;
        border-left: 1px dashed #FFFFFF;
        padding-top: 10px;
        padding-left: 10px;
        cursor: ew-resize;
        top: 0;
        bottom: 0;
        z-index: 1000;
      }
      position: absolute;
      display: block;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
    }
    .ejm-markers{
      .ejm-marker{
        background: #75882c;
        width: 80px;
        height: 200px;
        position: absolute;
        opacity: .3;
        top: 0px;
        left: 70px;
      }
      .ejm-marker-background{
        border-left: #404644 1px dashed;
        width: 1px;
        position: absolute;
        top: 0;
        left: 109.5px;
        bottom: 0;
        display:block;
      }
      position: absolute;
      display: block;
      width: 100%;
      height: auto;
      top: 30px;
      left: 0;
      pointer-events: none;
    }
    .ejm-lines{
      width: 100%;
      position: absolute;
      left: 0;
      top:30px;
      cursor: default;
      pointer-events: none;
    }
    display: block;
    width: auto;
    height: auto;
    position: relative;
  }
  display: block;
  width: 100%;
  height: 100%;
}
