.accounting_tenant_option {
  .panel-group {
    .noData {
      color: #fefefe;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .acc_text {
    width: 33%;
    display: inline-block;
  }
  .table_acc_show {
    padding: 0px 10px 10px 10px;
    div {
      color: #fefefe
    }
    h1 {
      font-size: 14pt;
    }
  }
  // Overwrite bootstrap classes
  .panel-default {
    .panel-heading {
      .title_border {
        border-top: 1px solid #000;
      }
      .accordion-toggle {
        &:after {
          font-family: $iconFontFamily;
          content: "[";
          float: left;
          color: #9dc427;
          width: 20px;
        }
        &.collapsed {
          &:after {
            font-family: $iconFontFamily;
            content: ">";
            float: left;
            color: #9dc427;
            width: 20px;
          }
        }
        border-bottom: 1px solid #000;
        display: block;
        color: #9dc427;
        padding: 10px 15px;
        font-size: 12px;
        outline: none;
      }
      background: #282e2c;
      border-color: #282e2c;
      padding: 0;
      border-radius: 0;
    }
    background: #282e2c;
    border-color: #282e2c;
    box-shadow: none;
  }

  .accountingTable {
    .header_date, .data_date, .footer_date {
      width: 20%;
    }
    .header_campaigns, .data_campaigns, .footer_campaigns {
      width: 40%;
    }
    .header_costs, .data_costs, .footer_costs {
      width: 40%;
    }
    td {
      padding: 10px 20px;
    }
    thead {
      tr {
        th {
          &:last-child {
            border-right: none;
          }
          padding: 10px 15px;
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: 1px solid #000;
        }
        td {
          &:last-child {
            border-right: none;
          }
          &.data_date {
            text-align: left;
          }
          white-space: nowrap;
          border-right: 1px solid #000;
        }
      }
      tr:nth-child(even) {
        background: #6f6f6f;
      }
      tr:nth-child(odd) {
        background: #474949;
      }
    }
    tfoot {
      td {
        &:last-child {
          border-right: none;
        }
        border-right: 1px solid #000;
      }
      span {
        font-weight: bold;
        float: right;
      }
    }
    white-space: nowrap;
    color: #fefefe;
    border-collapse: collapse;
    border-bottom: 1px solid #000;
    font-family: Tahoma, Verdana, Arial;
    font-size: 12px;
    width: 100%;
    text-align: right;
    background: #282e2c;
  }
  height: 100%;
}
