treecontrol {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* default */
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size:13px;
  color: #555;
  text-decoration: none;
}

treecontrol ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
}

treecontrol li {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 20px;
}

treecontrol li.tree-expanded span.tree-leaf-head, treecontrol li.tree-collapsed span.tree-leaf-head {display:none;}
treecontrol li.tree-expanded span.tree-branch-head, treecontrol li.tree-collapsed span.tree-branch-head {display:inline;}
treecontrol li.tree-leaf span.tree-branch-head {display:none;}
treecontrol li.tree-leaf span.tree-leaf-head {display:inline;}

treecontrol li span.tree-branch-head {
  cursor: pointer;
}

treecontrol li .tree-label {
  cursor: pointer;
  display: inline;
}

treecontrol li .tree-unselectable {
  cursor: default;
}

treecontrol.tree-classic li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder.png") no-repeat;
}

treecontrol.tree-classic li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder-closed.png") no-repeat;
}

treecontrol.tree-classic li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  background: url("../images/file.png") no-repeat;
}

treecontrol.tree-classic li .tree-selected {
  background-color: transparent;
  font-weight: bold;
}

treecontrol.tree-classic li .tree-unselectable {
  color: #ddd;
}

treecontrol.tree-light li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-opened-2.png") no-repeat;
}

treecontrol.tree-light li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-closed-2.png") no-repeat;
}

treecontrol.tree-light li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  width: 16px; height: 16px;
  background: none no-repeat;
}

treecontrol.tree-light li .tree-selected {
  font-weight: bold;
}

treecontrol.tree-light li .tree-unselectable {
  color: #ddd;
}

treecontrol.tree-dark li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-opened-light.png") no-repeat;
}

treecontrol.tree-dark li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-closed-light.png") no-repeat;
}

treecontrol.tree-dark li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  width: 16px; height: 16px;
  background: none no-repeat;
}

treecontrol.tree-dark li .tree-selected {
  font-weight: bold;
}

treecontrol.tree-dark li .tree-unselectable {
  color: #777;
}

treecontrol.tree-dark {
  color: #ddd;
}

treecontrol.tree-boot {
  color: #428bca;
  font-family: inherit;
  font-size: inherit;
}

treecontrol.tree-boot > ul > li {
  padding: 0;
}

treecontrol.tree-boot li > .tree-label {
  display: block;
  padding: 3px 0;
  border-radius: 4px;
}

treecontrol.tree-boot span.tree-branch-head,
treecontrol.tree-boot span.tree-leaf-head {
  float: left;
  height: 16px;
  margin: 5px;
}

treecontrol.tree-boot li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder.png") no-repeat;
}

treecontrol.tree-boot li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder-closed.png") no-repeat;
}

treecontrol.tree-boot li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  background: url("../images/file.png") no-repeat;
}

treecontrol.tree-boot .tree-label.tree-selected,
treecontrol.tree-boot .tree-label.tree-selected:hover {
  color: #fff;
  background-color: #428bca;
}

treecontrol.tree-boot .tree-label:hover {
  background-color: #eee;
}

//As attribute

[treecontrol] {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* default */
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size:13px;
  color: #555;
  text-decoration: none;
}

[treecontrol] ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
}

[treecontrol] li {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 20px;
}

[treecontrol] li.tree-expanded span.tree-leaf-head, [treecontrol] li.tree-collapsed span.tree-leaf-head {display:none;}
[treecontrol] li.tree-expanded span.tree-branch-head, [treecontrol] li.tree-collapsed span.tree-branch-head {display:inline;}
[treecontrol] li.tree-leaf span.tree-branch-head {display:none;}
[treecontrol] li.tree-leaf span.tree-leaf-head {display:inline;}

[treecontrol] li i {
  cursor: pointer;
}

[treecontrol] li .tree-label {
  cursor: pointer;
  display: inline;
}


[treecontrol].tree-classic li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder.png") no-repeat;
}

[treecontrol].tree-classic li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder-closed.png") no-repeat;
}

[treecontrol].tree-classic li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  background: url("../images/file.png") no-repeat;
}

[treecontrol].tree-classic li .tree-selected {
  background-color: #aaddff;
  font-weight: bold;
}


[treecontrol].tree-light li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-opened-2.png") no-repeat;
}

[treecontrol].tree-light li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-closed-2.png") no-repeat;
}

[treecontrol].tree-light li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  width: 16px; height: 16px;
  background: none no-repeat;
}

[treecontrol].tree-light li .tree-selected {
  font-weight: bold;
}


[treecontrol].tree-dark li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-opened-light.png") no-repeat;
}

[treecontrol].tree-dark li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/node-closed-light.png") no-repeat;
}

[treecontrol].tree-dark li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  width: 16px; height: 16px;
  background: none no-repeat;
}

[treecontrol].tree-dark li .tree-selected {
  font-weight: bold;
}

[treecontrol].tree-dark {
  color: #ddd;
}

[treecontrol].tree-boot {
  color: #428bca;
  font-family: inherit;
  font-size: inherit;
}

[treecontrol].tree-boot > ul > li {
  padding: 0;
}

[treecontrol].tree-boot li > .tree-label {
  display: block;
  padding: 3px 0;
  border-radius: 4px;
}

[treecontrol].tree-boot span.tree-branch-head,
[treecontrol].tree-boot span.tree-leaf-head {
  float: left;
  height: 16px;
  margin: 5px;
}

[treecontrol].tree-boot li.tree-expanded span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder.png") no-repeat;
}

[treecontrol].tree-boot li.tree-collapsed span.tree-branch-head {
  padding: 1px 10px;
  background: url("../images/folder-closed.png") no-repeat;
}

[treecontrol].tree-boot li.tree-leaf span.tree-leaf-head {
  padding: 1px 10px;
  background: url("../images/file.png") no-repeat;
}

[treecontrol].tree-boot .tree-label.tree-selected,
[treecontrol].tree-boot .tree-label.tree-selected:hover {
  color: #fff;
  background-color: #428bca;
}

[treecontrol].tree-boot .tree-label:hover {
  background-color: #eee;
}


//custom
.elaine-tree{
  *{
    outline: none
  }
  &.ng-hide-remove, &.ng-hide-add {
    transition: all linear .5s;
  }
  &.ng-hide {
    opacity: 0;
  }
  > .elaine-tree-list {
    > .elaine-tree-list-item {
      > div{
        text-transform: uppercase;
      }
      border-bottom: 1px solid #000;
    }
  }
  treeitem{
    .elaine-tree-list{
      .elaine-tree-list-item{
        .elaine-tree-label-container{
          padding: 0 0 0 20px;
        }
      }
    }
  }
  .elaine-tree-list{
    .elaine-tree-list-item{
      &.tree-expanded{
        .elaine-tree-label-container {
          span {
            &.elaine-tree-collapsed, &.elaine-tree-expanded {
              &:after {
                content: ']';
              }
            }
          }
        }
      }
      &.tree-collapsed{
        .elaine-tree-label-container {
          span {
            &.elaine-tree-collapsed, &.elaine-tree-expanded {
              &:after {
                content: '[';
              }
            }
          }
        }
      }
      &.hover,&.selected,&:focus{
        > .elaine-tree-label-container{
          background: #9dc427;
        }
      }
      .elaine-tree-label-container{
        span{
          &.tree-leaf-head,&.tree-branch-head{
            background: none;
          }
          &:not( .elaine-tree-list-item-leaf ){
            display: inline-block;
          }
          &.elaine-tree-list-item-leaf{
            display: none;
          }
          &.elaine-tree-collapsed{
            &:after{
              content: '[';
            }
          }
          &.elaine-tree-expanded{
            &:after{
              content: ']';
            }
          }
          height: 30px;
          width: 30px;
          line-height: 30px;
          vertical-align: top;
        }
        .elaine-tree-list-item-label{
          &.selected{
            background: transparent;
            font-weight: inherit;
          }
          height: 30px;
          line-height: 30px;
          display: inline-block;
          width: calc( 100% - 30px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: top;
          padding-left: 10px;
        }
        height: 30px;
        line-height: 30px;
      }
      width: 100%;
      display: inline-block;
      height: auto;
      line-height: 30px;
      padding: 0;
      vertical-align: top;
      color: #fefefe;
    }
    width: 240px;
    //max-width: 240px;
  }
  *{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  font-family: inherit;
  font-size: inherit;
  color: #fefefe;
  opacity: 1;
  display: block;
}
