//Container for stage headlines
.es-statistic-container {
  &.even {
    .odd {
      background: #282e2c;
    }
    .even {
      background: #1f2523;
    }
    background: #282e2c;
  }
  &.odd {
    .odd {
      background: #1f2523;
    }
    .even {
      background: #282e2c;
    }
    background: #1f2523;
  }
  display: block;
  overflow: hidden;
  height: auto;
}

// statistic navigation
.es-stage-navigation {
  .es-clicks-overview {
    border-bottom: 1px solid #000;
  }
  .e_form {
    .navigation-container {
      &.smallLabel {
        .form-group {
          &.form-group-select {
            label {
              line-height: 20px;
              width: 50px;
            }
          }
        }
        margin-right: 5px;
        width: 250px;
      }
      &_clicks {
        width: 240px;
      }
      .form-group {
        &.form-group-select {
          //padding-top: 0;
          .styled-select {
            width: 140px;
          }
          label {
            margin-left: 6px;
            line-height: 20px;
            width: 30%;
          }
          display: inline-flex;
          width: 100%;
          height: 30px;
        }
      }
      width: 250px;
      display: inline-block;
      margin-right: 20px;
    }
    .e_icn_btn {
      &.noPadding {
        padding: 0 11px;
      }
      &.marginRight {
        margin-right: 30px;
      }
      &.small {
        width: 30px;
        border-right: none;
        margin-left: 23px;
      }
      height: 30px;
      line-height: 35px;
    }
    .form-group {
      .switch-item {
        margin-right: 5px;
      }
      .switch-item-text {
        vertical-align: top;
      }
      &.switch {
        margin-right: 25px;
      }
      &.form-group-input {
        &.amount {
          .ui-spinner{
            .ui-spinner-button{
              span{
                font-size: 12px;
              }
              &.ui-spinner-down {
                top: 10px;
              }
              height: 10px;
              line-height: 10px;
            }
            height: 20px;
          }
          input{
            height: 20px;
            width: calc( 100% - 30px );
          }
          padding-top: 5px;
        }
      }
      &.form-group-select {
        &.small {
          width: 150px;
        }
        &.first {
          margin-right: 50px;
        }
        .styled-select {
          &:before {
            right: 0;
          }
          &:after{
            top: 4px;
          }
          margin-left: 5px;
          height: 20px;
        }
        padding-top: 5px;
        margin-right: 15px;
      }
      &.form-group-input-date, &.form-group-input-date-period {
        label{
          text-align: center;
        }
        .date-element {
          .input-group-addon {
            &.removeDate{
              line-height: 23px;
            }
            height: 20px;
            line-height: 21px;
          }
          .moment-picker-input {
            height: 22px;
          }
          .date-toggle {
            height: 20px;
          }
          padding-top: 5px;
        }
      }
      &.form-group-input-color {
        .input-group-addon {
          width: 30px;
          height: 20px;
          line-height: 21px;
          float: left;
        }
        .input-group {
          .sp-replacer{
            .sp-preview{
              height: 10px;
            }
            .sp-dd{
              line-height: 7px;
            }
            height: 20px;
          }
          .input-group-navigation {
            display: inline-block;
            width: 40px;
            height: 20px;
            float: left;
          }
          width: 100px;
        }
        padding-top: 5px;
      }
      margin: 0;
    }
    .chosen-container {
      .chosen-single{
        line-height: 20px;
      }
      &:before {
        height: 20px;
      }
      &:after {
        top: 3px;
      }
      height: 20px;
    }
    &-export-container {
      height: 29px;
      overflow: hidden;
    }
  }
  height: 30px;
  width: 100%;
  display: block;
}

.es-sidebar-menu {
  .es-sidebar-menu-body {
    .es-sidebar-table {
      .es-sidebar-table-body {
        .es-sidebar-table-row {
          &.clicked {
            background: #9dc427;
          }
          &.notActive {
            background: #404644;
          }
          &:not([disabled]) {
            &:hover, &:active, &:focus {
              background: #9dc427;
              border-color: #9dc427;
              outline-color: #9dc427;
            }
          }
          .es-sidebar-table-cell-checkbox {
            display: inline-block;
            float: left;
          }
          .es-sidebar-table-cell-big {
            &.folder {
              opacity: .5;
            }
            &[disabled] {
              opacity: .5;
              cursor: default;
            }
            display: inline-block;
            width: auto;
            float: left;
          }
          .es-sidebar-table-cell-small {
            display: inline-block;
            width: auto;
            float: right;
            margin-right: 5px;
            line-height: 30px;
          }
          width: 100%;
          display: inline-block;
          vertical-align: middle;
          line-height: 30px;
          padding-left: 5px;
        }
        display: block;
        width: 100%;
        height: auto;
      }
      display: inline-block;
      float: left;
      width: 200px;
      height: 100%;
      border-right: 2px solid #000;
    }
    display: block;
    width: 100%;
    height: 100%;
  }
  *{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  display: block;
  height: 100%;
  width: 100%;
  background: #282e2c;
  color: #fefefe;
}

.es-stage-item {
  &.last {
    .es-item-box {
      border: none;
    }
  }
  &.es-stage-item-col-1 {
    width: 25%;
  }
  &.es-stage-item-col-2 {
    width: 50%;
  }
  &.es-stage-item-col-3 {
    width: 75%;
  }
  &.es-stage-item-col-4 {
    width: 100%;
  }
  .es-item-box {
    .es-item-box-header {
      span {
        color: #fefefe;
        padding-left: 10px;
      }
      font-family: $fontFamilyLight;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      line-height: 30px;
      font-size: 16px;
      height: 30px;
    }
    .es-item-box-inner {
      &.headed {
        .es-item-box-inner-atom {
          padding: 0 10px 10px;
        }
        height: calc(100% - 30px);
      }
      .es-item-box-inner-atom {
        &.inner-up, .inner-down, .inner-full {
          overflow: hidden;
          display: block;
          width: 100%;
        }
        &.inner-up {
//          height: 50%;
          height: 42%;
        }
        &.inner-down {
          height: 50%;
        }
        &.inner-full {
          height: 100%;
        }
        &.spark {
          svg {
            overflow: visible;
          }
        }
        width: 100%;
        height: 100%;
        display: block;
        padding: 0;
      }
      &:first-of-type {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
      }
      &:last-of-type {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
      }
      overflow: hidden;
      float: left;
      height: 100%;
    }
    height: 100%;
    display: block;
    overflow: hidden;
    width: 100%;
    background: transparent;
    color: #fefefe;
    border-right: 1px solid #000;
  }
  &.es-stage-detail-item {
    .es-item-box {
      .es-item-box-inner {
        width: 100%;
      }
      border: none;
    }
    width: 100%;
    height: 100%;
  }
  padding: 20px 10px;
  height: 250px;
  float: left;
  overflow: hidden;
}

//ES-Trend-Chart
.es-trend-chart {
  .es-trend-chart-item {
    .es-trend-arrow-container {
      .es-trend-arrow {
        position: absolute;
        width: 30px;
        top: 10px;
      }
      position: relative;
      margin-left: 5px;
    }
    small {
      font-size: .6em;
    }
    font-family: $fontFamilyThin;
    font-size: 50px;
  }
}

//ES-Text-Chart
.es-text-chart {
  .es-text-chart-item {
    small {
      font-size: .6em;
    }
    font-family: $fontFamilyThin;
    font-size: 50px;
  }
}

//StatisticBoxHeadline
.statisticBoxHeadline {
  .name {
    width: 100%;
    display: inline-block;
    font-size: 30px;
  }
  .noName {
    display: inline-block;
    padding-top: 25px;
  }
  .dateTime {
    display: inline-block;
    font-size: 16px;
    margin-top: -5px;
  }
  color: #fefefe;
  padding: 10px;
  font-family: $fontFamilyThin;
}
/*heatmap chart*/
.es-heat-map{
  .es-heat-map-table {
    border-collapse: collapse;
  }

  .es-heat-map-th {
    border: none;
    width: 115px;
    font-weight: normal;
    padding-bottom: 12px;
  }

  .es-heat-map-td {
    padding: 5px 10px;
    border-top:1px solid #404644;
    border-bottom:1px solid #404644;
    height: 9px;
  }

  .time {
    .es-heatmap-th {
      text-align: right;
      padding-right: 10px;
      width: 70px;
      height: 19px;
      padding-bottom: 0;
    }
  }
  .scale{

  }
}
/*Horizontal Bar*/
.es-horizontal-bar {
  .es-horizontal-bar-header {
    span {
      font-size: 1.3em;
      color: #fefefe;
    }
    font-family: $fontFamilyLight;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    line-height: 17px;
    font-size: 16px;
    height: 30px;
  }
  .es-horizontal-bar-item-container {
    .es-horizontal-bar-item {
      &:hover {
        .es-horizontal-bar-item-chart {
          .progress {
            .progress-bar {
              opacity: 1;
            }
          }
        }
      }
      .es-horizontal-bar-item-legend {
        font-family: $fontFamilyLight;
        width: 100%;
        height: 20px;
        overflow: hidden;
        display: block;
      }
      .es-horizontal-bar-item-chart {
        .progress {
          .progress-bar {
            -webkit-transition: width 1s ease;
            -o-transition: width 1s ease;
            transition: width 1s ease;
            background-color: #009CC9;
            opacity: .75;
          }
          background: rgba(0, 0, 0, .31);
          height: 5px;
          border-radius: 0;
        }
        width: 100%;
        height: 20px;
        overflow: hidden;
        display: block;
      }
      display: block;
      overflow: hidden;
      line-height: 17px;
      height: 35px;
    }
  }
}

/*Detail Chart*/
.es-detail-chart {
  .es-detail-chart-item {
    .es-detail-chart-item-left {
      display: table-cell;
      min-width: 50px;
      padding-right: 5px;
      height: auto;
      text-align: right;
    }
    .es-detail-chart-item-right {
      display: table-cell;
      width: auto;
      height: auto;
    }
    font-family: $fontFamilyLight;
    display: table-row;
    width: 100%;
    overflow: hidden;
    height: auto;
  }
  padding: 5px;
  font-size: 16px;
  display: table;

}

/*KPI compare chart*/
.es-kpi-compare-chart {
  .es-kpi-compare-chart-left-container {
    width: 50%;
    display: inline-block;
    float: left;
  }
  .es-kpi-compare-chart-right-container {
    width: 50%;
    display: inline-block;
    float: left;
  }
}

/*Funnel chart*/
.es-funnel-chart {
  .es-funnel-chart-item {
    &:hover {
      .es-funnel-chart-item-left {
        .progress {
          .progress-bar {
            opacity: 1;
          }
        }
      }
    }
    .es-funnel-chart-item-left {
      .es-funnel-chart-values {
        font-family: $fontFamilyLight;
        font-size: 16px;
        line-height: 16px;
      }
      .es-funnel-chart-text {
        font-family: $fontFamilyLight;
        font-size: 12px;
      }
      float: left;
      width: 175px;
      text-align: right;
      padding-right: 10px;
    }
    .es-funnel-chart-item-right {
      .progress {
        .progress-bar {
          -webkit-transition: width 1s ease;
          -o-transition: width 1s ease;
          transition: width 1s ease;
          background-color: #009CC9;
          float: right;
          opacity: .75;
          min-width: 1px;
        }
        background: transparent;
        border-radius: 0;
        min-height: 30px;
        margin-bottom: 10px;
        border: none;
        box-shadow: none;
      }
      float: left;
      width: calc(98% - 195px);
      margin-right: 2%;
    }
    width: 100%;
    overflow: hidden;
    display: block;
  }
  padding-top: 15px;
}

/*Gauge Canvas*/
.ngpercentdisplay {
  background-color: #606060;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  float: left;
  margin: 10px;
  font-size: 24px;
}

.ngpercentdisplay > div {
  float: left;
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ngpercentdisplay span {
  background-color: #DADADA;
  display: block;
  width: 100%;
  height: 100%;
}

.ngpercentdisplay .ngperdispleft span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-transform-origin: 100% 50%;
}

.ngpercentdisplay .ngperdispright span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transform-origin: 0% 50%;
}

.ngpercentdisplay:before,
.ngpercentdisplay:after {
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
}

.ngpercentdisplay:before {
  background-color: #FFFFFF;
  content: "";
  width: 60%;
  height: 60%;
  z-index: 100;
}

.ngpercentdisplay:after {
  content: attr(data-percent) "%";
  font-family: Arial, sans-serif;
  font-weight: bold;
  z-index: 200;
  text-align: center;
}

//This scss file contains all styling's for the statistcs
.statisticOverview {
  overflow: hidden;
  height: auto;
  width: 100%
}

//Trend
.trendContainer {
  font-size: 48px;
}

//MailingTrend
.es-mailing-trend-chart {
  small {
    font-size: .6em;
  }
  .es-mailing-trend-text {
    .es-mailing-trend-text-left{
      display: table-cell;
      min-width: 50px;
      padding-right: 5px;
      height: auto;
      text-align: right;
    }
    .es-mailing-trend-text-right{
      display: table-cell;
      width: auto;
      height: auto;
    }
    font-size: 16px;
    font-family: $fontFamilyLight;
    display: table;
  }
  font-family: $fontFamilyThin;
  font-size: 50px;
}

//TextChart
.textContainer {
  padding: 5px;
  color: #fefefe;
  font-size: 48px;
  font-weight: bold;
}

//ChartContainer -> Charts get their height and width from.
.chartContainer {
  overflow: hidden;
  height: 100%;
  width: 100%
}

//BarChart
.barContainer {
  .dashboardItem {
  }
  padding: 5px;
}

//KPI
.kpiChartItem {
  .headline {
    display: inline-block;
  }
  .percent {
    font-size: 2.5em;
  }
  .trend {
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1.5em;
  }
  padding: 10px;
}

//PieChart
.pieChartItem {
  padding: 10px;
}

nvd3 {
  .nv-noData{
    fill: #fefefe;
  }
  * {
    font-family: $fontFamilyLight !important;
    font-weight: 100 !important;
  }
  //LineChart
  .nv-lineChart {
    .nv-x {
      .nv-axis {
        .tick {
          opacity: 0 !important;
        }
      }
    }
    .nv-y {
      .nv-axis {
        .tick {
          line {
            stroke: #696969 !important;
          }
        }
      }
    }
  }

  //HistoryBar
  .nv-historicalBarChart {
    .nv-x {
      .nv-axis {
        .tick {
          opacity: 0 !important;
        }
      }
    }
    .nv-y {
      .nv-axis {
        .tick {
          line {
            stroke: #696969 !important;
          }
        }
      }
    }
  }

  //Barchart
  .nv-discreteBarWithAxes {
    .nv-bar {
      text {
        fill: #fefefe !important;
      }
    }
    .nv-x {
      .nv-axis {
        .tick {
          line {
            opacity: 0 !important;
          }
          text {
            fill: #fefefe;
            font-size: 10px;
          }
        }
      }
    }
    .nv-y {
      .nv-axis {
        .domain {
          stroke: #696969;
        }
        .tick {
          line {
            stroke: #696969 !important;
          }
          text {
            fill: #fefefe;
            font-size: 10px;
          }
        }
        text {
          fill: #fefefe;
          font-size: 10px;
        }
      }
    }
  }
  //Multibarchart
  .nv-multiBarWithLegend {
    .nv-x {
      .nv-axis {
        .tick {
          line {
            opacity: 0 !important;
          }
          text {
            fill: #fefefe;
            font-size: 10px;
          }
        }
      }
    }
    .nv-y {
      .nv-axis {
        .domain {
          stroke: #696969;
        }
        .tick {
          line {
            stroke: #696969 !important;
          }
          text {
            fill: #fefefe;
            font-size: 10px;
          }
        }
        text {
          fill: #fefefe;
          font-size: 10px;
        }
      }
    }
  }
}

//"ProgressBar"
.progressContainer {
  .progressItem {
    h5 {
      margin-top: 0;
      font-size: 1.4em;
    }
    .mail_info_stats_single {
      &.conversions {
        .progress {
          .progress-bar {
            background-color: #009cc9;
          }
        }
      }
      &.unsubs {
        .progress {
          .progress-bar {
            background-color: #aa2921;
          }
        }
      }
      .progressName {
        width: 85px;
        display: block;
        float: left;
        overflow: hidden;
        padding-right: 5px;
        text-align: right;
      }
      .progress {
        .progress-bar {
          background: #9dc427;
          -webkit-transition: width .7s ease-in-out;
          -o-transition: width .7s ease-in-out;
          transition: width .7s ease-in-out;
        }
        background: transparent;
        border: 1px solid transparent;
        box-shadow: none;
        border-radius: 0;
        //        width: 55%;
        margin-bottom: 0;
        height: 12px;
      }
      margin-bottom: 5px;
      //      font-size: 1.1em;
      overflow: hidden;
      width: 100%;
    }
  }
}

//Detail pages -> bigger than overview
.statisticDetail {
  .statisticDetailPie {
    .chartContainer {
      width: 100%;
      height: 500px;
    }
  }
}

//GaugeChart
.gaugeChartItem {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", Verdana;
}

.gaugeChartItem svg {
  width: 100%;
  height: 100%;
}

.es-trend-arrow {
  &:before {
    font-family: elaine-six;
    content: '>'
  }
  font-size: 40px !important;
}

.es-statistic-container-compare {
  .es-state-compare-container {
    .es-state-compare-item {
      &:nth-child( even ) {
        .es-state-compare-item-container {
          border-left: 1px solid #000;
        }
      }
      .es-state-compare-item-container {
        .es-state-compare-item-header {
          h4 {
            margin: 0;
            font-size: 16px;
          }
          .es-header-type {
            text-transform: capitalize;
          }
          display: block;
          width: 100%;
          height: 40px;
          padding: 10px 10px 5px 20px;
        }
        .es-state-compare-item-body {
          .es-state-compare-item-body-top {
            .es-state-compare-item-body-left {
              .es-state-compare-item-main-value {
                .value {
                  small {
                    font-size: 75%;
                  }
                  display: block;
                  width: 100%;
                  font-size: 50px;
                }
                .text {
                  width: 100%;
                  display: block;
                  font-size: 16px;
                  color: #ccc;
                  font-weight: 100;
                }
                height: 100px;
              }
              width: 50%;
              height: 100%;
              display: block;
              float: left;
            }
            .es-state-compare-item-body-right {
              .ewe-popover-sparkline {
                height: 100px;
                padding-top: 10px;
              }
              width: 50%;
              height: 100%;
              display: block;
              float: left;
              padding: 0 5px;
            }
            overflow: hidden;
          }
          .es-state-compare-item-body-bottom {
            .header {
              font-size: 14px;
            }
            padding: 5px;
          }
          .es-state-compare-item-value {
            .value {
              display: inline-block;
              width: 50px;
              font-size: 16px;
              text-align: right;
              vertical-align: top;
            }
            .text {
              display: inline-block;
              width: calc(100% - 55px);
              font-size: 16px;
              padding-left: 5px;
            }
          }
          display: block;
          width: 100%;
          height: auto;
          padding: 0 10px 10px 20px;
        }
        display: block;
        height: 100%;
        width: 100%;
        overflow: auto;
        color: #fefefe;
        min-height: 231px;
      }
      &.odd {
        background: #1f2523;
      }
      display: inline-block;
      width: 50%;
      height: auto;
      background: #282e2c;
      overflow: hidden;
      list-style: none;
      //cursor: move;
      padding: 20px 10px 20px 0;
      vertical-align: top;
      min-height: 241px;
    }
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    overflow: hidden;
    list-style: none;
  }
  .es-statistic-container-compare-no-state, .es-statistic-container-compare-no-data {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 12px;
    color: #fefefe;
    font-family: $fontFamilyLight;
  }
  &.even {
    background: #1f2523;
  }
  display: block;
  width: 100%;
  height: 100%;
  font-family: $fontFamilyLight;
}

.es-message-click-overview-stage {
  .es-message-click-overview-stage-control {
    .e_form{
      padding: 0 5px;
    }
    display: block;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #000;
  }
  .es-message-click-overview-stage-inner {
    .es-message-overview-show {
      .em-html-variant-inner{
        background: #fefefe;
      }
      .es-message-overview-show-html{
        background: #fefefe;
      }
      .es-message-overview-show-inner {
        .es-message-overview-show-circle{
          position: absolute;
          border-radius: 50%;
          pointer-events: none;
        }
        .es-message-overview-show-link{
          position: absolute;
          z-index: 100;
          outline: none;
          cursor: pointer;
        }
        .es-message-overview-show-link-popup {
          .es-message-overview-show-link-popup-info {
            padding: 0 10px;
            background: rgba(64, 70, 68, 0.6);
            width: 100%;
            height: 30px;
            line-height: 30px;
            vertical-align: middle;
            color: #fefefe;
          }
          .es-message-overview-show-link-popup-tab {
            padding-left: 10px;
            line-height: 18px;
          }
          .es-message-overview-show-link-popup-funnel-container {
            height: 182px;
            background: rgba(64, 70, 68, 0.6);
          }
          .es-message-overview-show-link-popup-pie-container {
            height: 182px;
            background: rgba(64, 70, 68, 0.6);
          }
          .es-message-overview-show-link-popup-funnel-tab {
            span {
              &:hover, &:active, &:focus, &.clicked {
                color: #9dc427;
                border-bottom: 2px solid #9dc427;
              }
            }
            display: inline-block;
            width: 50px;
            outline: none
          }
          .es-message-overview-show-link-popup-pie-tab {
            span {
              &:hover, &:active, &:focus, &.clicked {
                color: #9dc427;
                border-bottom: 2px solid #9dc427;
              }
            }
            display: inline-block;
            width: 50px;
            outline: none
          }
          .es-message-overview-show-link-popup-url {
            font-family: $fontFamilyLight;
            font-size: 16px;
            line-height: 30px;
            text-overflow: ellipsis;
            width: 340px;
            height: 30px;
            white-space: nowrap;
            overflow: hidden;
            padding: 0 10px;
          }
          .es-stage {
            .es-stage-container {
              .es-stage-item {
                .es-item-box {
                  .es-item-box-inner {
                    .es-item-box-inner-atom {
                      .es-funnel-chart {
                        .es-funnel-chart-item {
                          .es-funnel-chart-item-left {
                            width: 150px;
                          }
                          .es-funnel-chart-item-right {
                            width: calc(100% - 160px);
                          }
                        }
                      }
                      padding: 0;
                    }
                    padding: 0;
                  }
                }
                padding: 0;
              }
              height: 100%;
            }
            height: 100%;
          }

          &.active {
            display: block;
          }
          color: #fefefe;
          position: absolute;
          display: none;
          width: 350px;
          height: 260px;
          background: rgba(40, 46, 44, 0.95);
          z-index: 1000
        }
        .es-message-overview-show-rectangle{
          position: absolute;
        }
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        outline: none;
      }
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding: 75px 0;
    }
    display: block;
    width: 100%;
    height: calc(100% - 30px);
  }
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.es-heatmap-container{
  .es-heatmap-container-inner{
    .es-heatmap-table {
      thead{
        tr{
          th{
            &:first-of-type{
              width: 50px;
            }
            &.addition{
              height: 8px;
              display: block;
            }
            border: none;
            width: 110px;
            font-weight: normal;
            padding: 0 10px;
            text-align: center;
          }
        }
      }
      tbody{
        tr{
          th{
            span{
              position: absolute;
              top: -7px;
              left: -7px;
              display: block;
              width: 50px;
              height: 100%;
            }
            text-align: right;
            padding: 0 10px;
            position: relative;
          }
          td {
            padding: 0;
            height: 20px;
            width: 100px;
            border-top:1px solid #404644;
            border-bottom:1px solid #404644;
          }
        }
      }
      border-collapse: collapse;
      color: #fefefe;
      margin: 20px;
      display: inline-table;
    }
    .es-heatmap-legend-container{
      .es-heatmap-legend{

        display: block;
        position: absolute;
        top: 45%;
        transform: rotate(90deg);
        height: 25px;
        width: 250px;
        left: -90px;
        background: -webkit-linear-gradient(left,  rgba(255,0,0, 1), rgba(255,64,0, 1), rgba(255,255,0, 1),rgba( 0, 0, 0, 0 ));
        background: -o-linear-gradient(left,  rgba(255,0,0, 1), rgba(255,64,0, 1), rgba(255,255,0, 1),rgba( 0, 0, 0, 0 ));
        background: -moz-linear-gradient(left,  rgba(255,0,0, 1), rgba(255,64,0, 1), rgba(255,255,0, 1),rgba( 0, 0, 0, 0 ));
        background: linear-gradient(to right, rgba(255,0,0, 1), rgba(255,64,0, 1), rgba(255,255,0, 1),rgba( 0, 0, 0, 0 ))
      }
      .es-heatmap-scale{
        .scaleContainer{
          .labeled{
            color: #fefefe;
          }
          display: block;
          position: relative;
          top: -7%;
          height: 118px;
          width: 55px;
          left: 120px;
        }
        position: absolute;
        top: 13%;
        height: 25px;
        width: 130px;
        left: -65px;
      }

      display: inline-table;
      width: 100px;
      height: 350px;
      position: relative;
      top: 100px;
    }
    display: block;
    min-width: 950px;
    width: 100%;
    height: auto;
  }
  display: block;
  width: 100%;
  height: 100%;
}

.nvtooltip {
  table {
    &.custom-tooltip-table {
      background: #474949;
      color: #fefefe;
    }
    tbody {
      tr {
        td {
          &.custom-tooltip-headline {
            font-weight: bold;
          }
        }
      }
    }
  }
  font-family: $fontFamilyLight;
}

loading-spinner.es-stage__spinner {
  top: -50%;
}
