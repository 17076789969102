$validation-result__A0: themed('base--text-color') !default;
$validation-result__B0: themed('success--color') !default;
$validation-result__C0: themed('error--color') !default;
$validation-result__D0: themed('warning--color') !default;
$validation-result__E0: themed('info--color') !default;

.d__validation-result{
  &__container{
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  &__tabs{
    md-tabs-content-wrapper{
      top: 30px;
    }
    md-tabs-canvas{
      height: 30px;
    }
    .md-tab{
      &.md-active{
        border-bottom: 2px solid #9dc427;
      }
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      padding: 0 10px;
      color: $validation-result__A0;
    }
  }
  &__field{
    .me__icn{
      &.info,&.notice{
        color: $validation-result__E0;
      }
      &.warning{
        color: $validation-result__D0;
      }
      &.deprecated{
        color: $validation-result__D0;
      }
      &.error{
        color: $validation-result__C0;
      }
      vertical-align: bottom;
    }
    legend{
      color: $validation-result__A0;
      border-color: $validation-result__A0;
      margin-bottom: 10px;
    }
    display: block;
    width: 100%;
    height: auto;
    padding: 5px;
    margin-bottom: 10px;
  }
  &__item{
    display: block;
    width: 100%;
    height: auto;
    color: $validation-result__A0;
    margin-bottom: 10px;
    border-bottom: 1px solid $validation-result__A0;
    overflow: hidden;
  }
  &__item-left{
    display: block;
    float: left;
    width: 50px;
    height: 50px;
  }
  &__item-right{
    span {
      display: block;
      width: 100%;
      word-break: break-word;
    }
    display: block;
    float: left;
    width: calc( 100% - 51px );
    height: auto;
  }
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
