.elaine-tasks {
  .elaine-tasks-container {
    &:last-of-type {
      text-align: right;
      margin-right: 10px;
      float: right;
    }

    #stime {
      line-height: 30px;
    }

    .elaine-task-button {
      &:hover, &.active {
        background: #9dc427;
        color: #fefefe;
        text-decoration: none;
      }

      .elaine-task-button-title {
        display: inline-block;
        width: 40px;
        height: 100%;
        vertical-align: top;
        line-height: 30px;
      }

      .elaine-task-button-inner {
        &.icon {
          padding-top: 10px;
        }

        display: inline-block;
        width: calc(100% - 45px);
        height: 100%;
        vertical-align: top;
        line-height: 30px;
        padding-top: 5px;
      }

      display: inline-block;
      width: 80px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      padding: 0 10px;
      color: #fefefe;
      text-decoration: none;
      outline: none;
    }

    display: inline-block;
    width: 39%;
    height: 100%;
    color: #fefefe;
  }
}

.elaine-job-control {
  &.ng-hide {
    height: 0;
  }

  &.ng-hide-add,
  &.ng-hide-remove {
    -moz-transition: .5s linear height;
    -webkit-transition: .5s linear height;
    transition: .5s linear height;
    overflow: hidden;
  }

  .elaine-job-control-container {
    .elaine-job-control-inner {
      .elaine-job-control-list {
        .elaine-job-control-list-item {
          &:hover {
            &:nth-child( odd ) {
              background: #9dc427;
            }

            .elaine-job-control-progress {
              .elaine-progress {
                .elaine-progress-bar {
                  background: #fefefe;
                }
              }
            }

            background: #9dc427;
          }

          &:nth-child( odd ) {
            background: #1f2523;
          }

          .elaine-progress-none-title {
            padding: 0 10px;
          }

          .elaine-progress-title {
            display: inline-block;
            width: 145px;
            height: 30px;
            line-height: 30px;
            padding: 0 10px;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .e_icn {
            display: inline-block;
            width: 20px;
            height: 30px;
            line-height: 30px;
            vertical-align: top;
            cursor: pointer;
          }

          .elaine-job-control-progress {
            .elaine-progress {
              .elaine-progress-bar {
                float: left;
                width: 0;
                height: 100%;
                font-size: 12px;
                line-height: 20px;
                color: #fefefe;
                text-align: center;
                background-color: #9dc427;
                -webkit-transition: width .6s ease;
                -o-transition: width .6s ease;
                transition: width .6s ease;
              }

              overflow: hidden;
              height: 15px;
              border: 1px solid #fefefe;
              background-color: transparent;
              margin-top: 7px;
            }

            display: inline-block;
            width: calc(100% - 175px);
            height: 30px;
            line-height: 30px;
            vertical-align: top;
          }

          display: block;
          width: 100%;
          height: 30px;
          line-height: 30px;
          padding: 0;
          margin: 0;
          border-bottom: 1px solid #000;
          list-style: none;
        }

        display: block;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style: none;
      }

      display: block;
      width: 100%;
      height: auto;
    }

    display: block;
    width: 240px;
    height: 100%
  }

  position: absolute;
  bottom: 36px;
  left: 0;
  display: block;
  width: 100%;
  height: 150px;
  max-height: 150px;
  background: #282e2c;
  color: #fefefe;
  border-top: 6px solid #000;
}

.elaine-job-control-progress-view {
  .elaine-job-control-progress-view-row {
    &:nth-child( odd ) {
      background: #1f2523;
    }

    .elaine-job-control-progress-view-field {
      &:first-of-type {
        padding: 0 10px;
      }

      &:last-of-type {
        width: calc(100% - 125px);
      }

      display: inline-block;
      vertical-align: top;
      height: 30px;
      width: 120px;
    }

    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #000;
  }

  display: block;
  width: 300px;
  height: auto;
  min-height: 50px;
  position: absolute;
  left: 0;
  z-index: 1001;
  color: #fefefe;
  background: #282e2c;
  border: 1px solid #000;
}
