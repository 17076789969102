$md-button__B0: "elaine-six";
$md-button__A0: themed('element-action--text-color') !default;
$md-button__E0: themed('element-action:hover--bg-color') !default;
$btn-hover: rgba(255, 255, 255, 0.4);
$input-bg: rgba(255, 255, 255, 0.2);
$element-height: 35px;
$gap: 5px;
$search-input-height: calc(#{$element-height} - 9px);

.gearMenu {
    right: 0 !important;
    left: calc(100% - 180px) !important;
    width: 180px;
}

.tb {
    color: white;
    font-family: $fontFamilyMedium;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 17px;

    .left-menu {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
    
    .flex-wrapper {
        display: flex;
        align-items: center;

        .hidden {
            display: none;
        }
    }

    .toolbar_search {
        margin-left: 5px;

        input {
            background: $input-bg;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border: 1px solid transparent;
            height: $search-input-height;
            line-height: $element-height;
            font-family: $fontFamilyLight;

            &::placeholder {
                color: white;
                font-size: 14px;
            }

            &:focus {
                border: 1px solid $btn-hover !important;
            }

            &:focus::placeholder {
                color: transparent;
            }
        }

        .search-btn {
            justify-content: center;
            margin-left: 1px !important;
            background: $input-bg !important;
            border-radius: 0 5px 5px 0 !important;
            height: 25px;
            min-height: 25px;
            

            &:hover {
                background: $btn-hover !important;
            }
        }
    }

    .left-icon {
        margin-right: 8px;
        height: 24px;
        width: 24px;
    }

    .right-menu {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-family: $fontFamilyMedium;

        &__config-container {
            gap: $gap;
            

            .config-item {
                padding: 5px 10px;
                height: $element-height;
                border: solid 1px transparent;
                border-radius: 4px;
                white-space: nowrap;
            }
            
        }

        &__gear-container {
            position: relative;

            .release-notes-icon {
                position: absolute;
                top: 3px;
                right: 3px;
                width: 10px;
                height: 10px;
                background-color: #EC2C2C;
                border-radius: 50%;
                z-index: 10;
            }
        }
    }

    .logo {
        width: 115px;
        margin-right: 10px;
    }

    .md-button {
        &[disabled] {
            opacity: .5;
        }

        &.tb__button {
            display: flex;
            align-items: center;

            border: 1px solid transparent;
            border-radius: 4px;
            color: $md-button__A0;
            height: $element-height;
            min-height: $element-height;
            line-height: $element-height;
            outline: none;
            vertical-align: top;
            text-align: center;

            margin: 0;
            padding: 0 10px;
            text-transform: none;

            &:focus {
                outline: none;
                border: 1px solid $btn-hover;
            }

            &:not( .tb__button--icon ) {
                min-width: 60px;
                width: auto;

                span {
                    display: flex;
                    margin-left: 4px;
                    margin-top: 1px;
                }
            }

            &.tb__button--icon {
                min-width: $element-height;
                min-height: $element-height;
                height: $element-height;
                width: $element-height;
                padding: 0;
                font-family: $md-button__B0;
            }

            &.active {
                background: $md-button__E0;
            }

            &[disabled] {
                opacity: .6;
            }

            &:hover {
                &:not( [disabled] ) {
                    background: $btn-hover;
                    border: 1px solid transparent;
                }
            }

            &.search-btn {
                height: $search-input-height;
                min-height: $search-input-height;
            }
        }

        &.round-icon {
            justify-content: center;
            border-radius: 50%;

            a {
                display: flex;
                align-items: center;
            }

            img {
                height: 24px;
                width: 24px;
            }
        }
    }

    .survey {
        display: flex;
        align-items: center;

        .feedback-btn {
            font-family: $fontFamilyMedium;
            letter-spacing: 0px;
            background: $input-bg;
            height: 36px;
        }
    }

    .survey, .feedback {
        a {
            color: #ffffff;
        }
    }
}

