//Framework
html {
  body{
    input::-webkit-calendar-picker-indicator {
      display: none;
    }
    /**Questionable as from bootstrap*/
    .invisible {
      visibility: hidden;
    }
    .pull-right {
      float: right !important;
    }
    .pull-left {
      float: left !important;
    }
    b,
    strong {
      font-weight: bold;
    }
    small {
      font-size: 80%;
    }
    /***/
    *{

      &:after, &:before{
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      text-rendering: geometricPrecision;
      outline: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    button{
      outline: none !important;
    }
    .dropdown-menu{
      webkit-box-shadow: $boxShadow;
      box-shadow: $boxShadow;
    }
    .accessibility-text{
      height: 1px;
      left: -10000px;
      overflow: hidden;
      position: absolute;
      top: auto;
      width: 1px;
    }
    [ng\:cloak],[ng-cloak],.ng-cloak{
      display:none !important
    }
    .elaine-server-connect{
      display: none;
      width: 300px;
      height: 76px;
      position: absolute;
      z-index: 10;
      top: 1px;
      right: 1px;
    }
    .elaine-server-exec{
      display: none;
      width: 300px;
      height: 38px;
      position: absolute;
      z-index: 10;
      top: 1px;
      left: 200px;
    }
    .sr-only{
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    .elaine-shade{
      &.ng-hide-remove, &.ng-hide-add {
        transition: all linear .5s;
      }
      &.ng-hide {
        opacity: 0;
      }
      position: absolute;
      top: 0;
      bottom:0;
      left:0;
      right:0;
      background: #282e2c;
      z-index: 1000;
    }
    /*For Spinner*/
    .ui-spinner-button{
      &:hover{
        background: #9dc427;
      }
      &.ui-spinner-up{
        &:after{
          font-family: elaine-six;
          content: "]";
          color: #fefefe;
          position: absolute;
          left: 8px;
        }
        color: transparent;
        position: relative;
      }
      &.ui-spinner-down{
        &:after{
          font-family: elaine-six;
          content: "[";
          color: #fefefe;
          position: absolute;
          left: 8px;
        }
        color: transparent;
        position: relative;
      }
    }

    /*FOR ICONS USE*/
    .e_icn, .ui-icon {
      &.e-icn-5x{
        font-size: 3em;
      }
      &.e-icn-4x{
        font-size: 2.5em;
      }
      &.e-icn-3x{
        font-size: 2em;
      }
      &.e-icn-2x{
        font-size: 1.5em;
      }
      &.warning{
        color: #c28325;
      }
      &.spinning{
        display: block;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: rotate;
        -moz-animation-duration: 1s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      font-family: $iconFontFamily;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      width: auto;
      color: #fefefe;
      text-rendering: optimizeLegibility;
      font-variant: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      text-transform: none;
    }
    /*Custom SCROLLER*/
    .jspScrollable {
      outline: none;
    }
    .jspVerticalBar{
      width: 7px;
    }
    .jspHorizontalBar{
      height: 7px;
    }
    .jspCorner{
      background: #000 !important;
    }
    .jspTrack{
      .jspDrag{
        background: #585e5e;
      }
      background: #000;
    }

    /*main stage*/
    .elaine-main-stage {
        toolbar {
            display: block;
            width: 100%;
        }

        .elaine-main-stage-menu {
            background: linear-gradient(90deg, #9dc427, #35a292);
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            position: relative;
        }

      .elaine-main-stage-left{
        border-top: 4px solid black;

        .elaine-main-stage-logo{
          .elaine-main-stage-logo-inner{
            .elaine-main-stage-logo-product{
              font-size: 12px;
              color: #fefefe;
              line-height: 12px;
              text-transform: uppercase;
              padding: 5px;
            }
            display: block;
            width: 65px;
            height: 65px;
            background: #9dc427;
          }
          display:block;
          width: 100%;
          height: auto;
          padding: 5px;
        }
        *{
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        display: block;
        width: 75px;
        height: 100%;
        float: left;
        background: #000;
      }
      .elaine-main-stage-right {
        .elaine-main-stage-view {
          border-top: 4px solid black;

          .ui-layout-center {
            display: block;
            width: 100%;
            height: calc( 100% - 30px );

            .elaine-center-layout {
              height: 100%;
              .dashboard-layout {
                height: 100%;
                .dashboard-frame {
                  height: 100%;
                  width: 100%;
                  border: 0;
                }
              }
              .elaine-panels {
                  height: 100%;
              }
            }
          }
          .elaine-main-stage-list{
            .elaine-main-stage-list-inner{
              &.ng-hide-remove, &.ng-hide-add {
                transition: all linear .5s;
              }
              &.ng-hide {
                opacity: 0;
              }
              display: block;
              width: 100%;
              height: 100%;
              opacity: 1;
              position: relative;
            }
            z-index: 3 !important;
          }
          .elaine-d-view-inner{
            display: block;
            width: 100%;
            height: 100%;
          }
          display: block;
          width: 100%;
          height: calc( 100% - 48px );
          position: relative;
          background: #282e2c;
        }
        display: block;
        width: calc( 100% - 75px );
        height: 100%;
        float: left;
        position: relative;
      }
      display: block;
      width: 100%;
      height: 100%;
    }
    /*misc*/
    div {
      &#preview {
        .mail-show-td {
          .preview_frame {
            display: block;
            margin-left: auto;
            margin-right: auto;
            background: #fff;
            width: 100%;
            height: 100%;
          }
          .preview_frame_text {
            width: 100%;
            height: calc(100% - 61px);
            background: #fff;
          }
          background: #282e2c;
        }
        width: 100%;
        height: 100%;
      }
      &#source {
        width: 100%;
        height: 100%;
        background: #282e2c;
      }
      &#statsframe {
        height: calc(100% - 62px) !important;
        margin-top: 31px;
      }
      /*ACE EDITOR*/
      &.source {
        .jspContainer {
          background: #000;
        }
        .ace-twilight {
          .ace_gutter {
            background: #282e2c;
          }
          .ace_scrollbar {
            display: none;
          }
        }
      }
    }
    /*NOTY MESSAGES*/
    #noty_topCenter_layout_container{
      .noty_icn_success{
        color: #9ec21a;
      }
      .noty_icn_warning{
        color: #c28325;
      }
      .noty_icn_info{
        color: #fefefe;
      }
      .noty_icn_error{
        color: #c31a1d;
      }
      >div{
          .noty_message{
            .noty_inner{
              .noty_inner_left,
              .noty_inner_right{
                float: left;
                padding: 4%;
              }
              .noty_inner_left{
                width: calc( 100% - 65px );
                padding: 0;
                font-size: 1.2em;
              }
              .noty_inner_right{
                span{
                  font-size: 40px;
                }
                width: 65px;
                height: 100%;
                padding: 0;
                text-align: right;
              }
              display: block;
              overflow: hidden;
              width: 100%;
              height: 100%;
              padding: 30px 20px;
            }
            height: auto !important;
            padding: 0 !important;
            text-align: left !important;
          }
        width: 100% !important;
        height: auto !important;
        border: none !important;
        background-color: #1f2523 !important;
        border-radius: 0 !important;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 8px !important;
      }
      width: 500px !important;
      max-width: 500px;
      left: calc( 50% - 250px ) !important;
    }

    #noty_contentservice_container{
      &.addvanceMode{
        height: 700px;
      }
      background: #1f2523 !important;
      width: 350px;
      border-bottom: 0px !important;
      .noty_body {
        padding: 0px !important;
        height: 100%;
      }
      iframe {
        height: 100%;
        width: 100%;
      }
    }
    /*PRODUCT SHOW*/
    .systemShow{
      .productShow{
        .greenSymbol{
          .productName{
            width: 50px;
            line-height: 12px;
            color: #fefefe;
            font-size: 12px;
            text-transform: uppercase;
          }
          .clName{
            color: #fefefe;
            position: absolute;
            bottom: 7.5%;
            left: 10%;
            font-size: 10px;
          }
          background: #9dc427;
          height: 65px;
          width: 65px;
          padding: 5px;
          position: relative;
        }
        margin-top: 5px;
        margin-left: 5px;
      }
    }

    .e-system-notifications-container{
      .listview_table#system-notifications-table {
        th {
          &.sorting, &.sorting_asc, &.sorting_desc {
            background: #404644;
          }
          &.sorting:after {
            content: '';
            float: right;

          }
          &.sorting_asc:after {
            content: ']';
            font-family: $iconFontFamily;
            color: #fefefe;
            opacity: 1;
            float: right;
          }
          &.sorting_desc:after {
            content: '[';
            font-family: $iconFontFamily;
            color: #fefefe;
            opacity: 1;
            float: right;
          }
        }
        tbody {
          .dtRow {
            cursor: default;
          }
          .dtRow:hover {
            color: inherit;
          }
        }

      }
      .no-notifications-msg {
        padding: 10px;
      }

      height: 595px;
    }

    .e-system-notifications-container.open-on-init {
      height: 550px;
    }
    
    code{
      background: #404644;
      color: #fefefe;
    }
    /*UI LAYOUT*/
    .ui-layout-resizer{
      background: #000;
    }

    .ui-layout-toggler{
      &.ui-layout-toggler-west,
      &.ui-layout-toggler-east{
        &:before {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
          display: inline-block;
          line-height: 1;
          width: 3px;
        }
      }
      &.ui-layout-toggler-north,
      &.ui-layout-toggler-south{
        &:before {
          height: 6px;
          line-height: 4px;
        }
      }
      &:before {
        content: "\2219\2219\2219";
        font-size: 28em;
        line-height: 0;
        color: #575958;
        font-weight: bold;
      }
      background: transparent;
      color: #545655;
    }

    .ui-layout-pane{
      background: #282e2c;
    }

    abbr{
      &[title]{
        border: none;
      }
      border: none;
    }
    .invisible_text{
      position:absolute;
      left:-10000px;
      top:auto;
      width:auto;
      height:1px;
      overflow:hidden;
    }
    .framework_panel{
      width: 100%;
      height: 100%;
    }


    &.statistik_mailing,&.composing_preview_inner,&.import_preview,&.emailshots_loading{
      overflow: auto !important;
    }
    &.template,&.list{
      height: 94% !important;
    }
    .ui-widget-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000 !important;
      opacity: .3;
      filter: Alpha(Opacity=30);
      z-index: 20005;
    }
    .ui-dialog{
      .ui-dialog-titlebar{
        .ui-dialog-title{
          color: #d7d9d9;
          font-family: $fontFamilyLight;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
          margin-top: 0;
          text-transform: uppercase;
          padding-left: 10px;
          padding-top: 45px;
          position: absolute;
        }
        background-color: #282d2c;
        background-image: url("../dialog/bgr_top.gif");
        background-position: right top;
        background-repeat: no-repeat;
        border-color: #716f64 #8d8b82 #8d8b82 #716f64;
        border: 1px solid;
        border-bottom: none;
        height: 88px;
        overflow-y: hidden;
        width: 100%;
        z-index: 1;
      }
      .ui-dialog-buttonpane{
        background-color: #282d2c;
        border-top: 1px solid black;
        height: 30px;
        overflow-y: hidden;
        padding: 10px;
        z-index: 10007;
      }
      border: 8px solid #293939;
      border-radius: 4px;
    }
    .ui-widget-content {
      background-color: #e1e2df;
      color: #222222;
      z-index: 999;
    }
    .multiple{
      input,select{
        width: 70.5% !important;
        margin-bottom: 2%;
      }
      input{
        &.dateFilterInput{
          width: 64.35% !important;
        }
      }
      button{
        width: 14.5% !important;
        float: left;
      }
      a{
        &.dateFilterLink{
          img{
            width: 5% !important;
          }
        }
      }
    }
    height: 100%;
    color: #444444;
    font-family: $fontFamilyLight;
    font-size: 12px;
    cursor: default;
    background-color: #000000;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  .elaine-error-with-icon{
    .e_icn{
      position: absolute;
      top: 0;
      left: 0;
    }
    position:relative;
    padding-left: 20px;
  }
  .mce-container{
    z-index: 202 !important;
  }
  .mce-fade{
    z-index: 201 !important;
  }
  height: 100%;
  font-family: $fontFamilyLight;
  font-size: 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


//Elaine dropdown
.elaine-dropdown-container{
  &:focus{
    .elaine-dropdown-display{
      background: #9dc427;
    }
  }
  &.left{
    .elaine-dropdown-list{
      left: 0;
    }
  }
  &.right{
    .elaine-dropdown-list{
      right: 0;
    }
    float: right;
    border-left: 1px solid #000;
  }
  &.iconized{
    max-width: 35px;
  }
  .elaine-dropdown-display{
    &.clicked,&:hover{
      background:#9dc427
    }
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
  }
  .elaine-dropdown-list{
    .elaine-dropdown-spacer{
      .elaine-dropdown-spacer-inner{
        height: 1px;
        background: #404644;
      }
      height: 1px;
      background: transparent;
      padding: 0 8px;
    }
    li{
      &:hover:not( [disbaled] ):not( .disabled ),&:focus:not( [disbaled] ):not( .disabled ){
        a{
          color: #9dc427;
        }
      }
      &:hover,&:focus{
        background: transparent;
      }
      a{
        display: block;
        width: auto;
        height: 30px;
        white-space: nowrap;
        text-decoration: none;
        padding: 0 10px;
        color: #fefefe;
        outline: none;
      }
      display: block;
      width: auto;
      height: 30px;
      line-height: 30px;
      list-style: none;
      border: none !important;
      outline: none;
    }
    display: block;
    width: auto;
    height: auto;
    list-style: none;
    color: #fefefe;
    background: #282e2c;
    padding: 0;
    margin: 1px 0 0 0;
    box-shadow: $boxShadow;
    position: absolute;
    top: 100%;
    z-index: 1001;
  }
  display: block;
  width: auto;
  height: auto;
  position: relative;
  color: #fefefe;
  //  text-transform: uppercase;
}

//image cropper
.elaine-cropper{
  .elaine-cropper-left{
    .elaine-cropper-stage{
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #000;
    }
    display: inline-block;
    width: 69%;
    height: 100%;
    vertical-align: top;
    padding: 15px 10px;
  }
  .elaine-cropper-right{
    .elaine-cropper-preview{
      .elaine-cropper-preview-img{
        width: 100%;
      }
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      overflow: hidden;
    }
    display: inline-block;
    width: 30%;
    height: 100%;
    vertical-align: top;
    padding: 15px 10px;
  }
  display: block;
  width: 100%;
  height: 100%;
}
//Accordion
.elaine-accordion{
  .elaine-accordion-pane{
    .elaine-accordion-pane-header{
      .e_icn{
        color: #9dc427;
        margin-right: 10px;
      }
      a{
        &:hover{
          color: #9dc427;
        }
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        color: #9dc427;
        text-decoration: none;
        outline: none;
      }
      small{
        float: right;
      }
      display: block;
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    .elaine-accordion-pane-body{
      &.ng-hide-add.ng-hide-add-active,
      &.ng-hide-remove.ng-hide-remove-active {
        -webkit-transition:all linear 0.5s;
        transition:all linear 0.5s;
        overflow: hidden;
      }
      &.ng-hide {
        max-height: 0;
        line-height:0;
        opacity:0;
      }
      display: block;
      width: 100%;
      height: auto;
      opacity:1;
    }
    display: block;
    width: 100%;
    height: auto;
  }
  display: block;
  width: 100%;
  height: auto;
}

//DatePicker
.DatePicker{
  th{
    &.year{
      border-top: 1px solid #848284;
      font-weight: normal;
      color: #444444;
      text-decoration: none;
      font-size: 12px;
    }
    &.month{
      font-weight: normal;
      color: #444444;
      text-decoration: none;
      font-size: 12px;
    }
    &.day{
      border-bottom: 1px solid #848284;
      font-weight: normal;
      color: #444444;
      text-decoration: none;
      font-size: 12px;
    }
    margin: 0px;
    padding: 0px;
  }
  td{
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-size: 12px;
    border: 1px solid #848284;
  }
  input{
    &.time{
      width: 25px;
    }
  }
  div{
    &.over{
      border: 1px solid #519AA9;
      background-color: #AFE2FD;
    }
    &.down{
      border: 1px solid #519AA9;
      background-color: #08C7FF;
    }
    &.selected{
      border: 1px solid #519AA9;
      background-color: #08C7FF;
    }
    &.down,&.selected{
      a{
        color: #FFFFFF;
      }
    }
    border: 1px solid #F8F7F6;
    background-color: #F8F7F6;
  }
  a{
    font-weight: normal;
    color: #444444;
    text-decoration: none;
  }
  width: 132px;
  border: none;
  border-collapse: collapse;
  margin: 0px;
  padding: 0px;
}

@-webkit-keyframes rotate {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}

@-moz-keyframes rotate {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(360deg);}
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

/* Spinner Square*/
.elaine-spinner-square{
  & > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
/*Spinner*/


.thumbnail-center .elaine-spinner.e-spin-2x.e-floating-circles-container{

  left: -10%;
  top:40%;

}

.elaine-spinner{
  &.right{
    float: right;
  }
  &.e-spin-1x{
    &.e-floating-circles-container{

      .e-floating-circles{

        &.e-floating-circle-1{
          left:0;
          top:8px;
          animation-delay:0.2895s;
          -o-animation-delay:0.2895s;
          -ms-animation-delay:0.2895s;
          -webkit-animation-delay:0.2895s;
          -moz-animation-delay:0.2895s;
        }

        &.e-floating-circle-2{
          left:2px;
          top:2px;
          animation-delay:0.386s;
          -o-animation-delay:0.386s;
          -ms-animation-delay:0.386s;
          -webkit-animation-delay:0.386s;
          -moz-animation-delay:0.386s;
        }

        &.e-floating-circle-3{
          left:8px;
          top:0;
          animation-delay:0.4825s;
          -o-animation-delay:0.4825s;
          -ms-animation-delay:0.4825s;
          -webkit-animation-delay:0.4825s;
          -moz-animation-delay:0.4825s;
        }

        &.e-floating-circle-4{
          right:2px;
          top:2px;
          animation-delay:0.589s;
          -o-animation-delay:0.589s;
          -ms-animation-delay:0.589s;
          -webkit-animation-delay:0.589s;
          -moz-animation-delay:0.589s;
        }

        &.e-floating-circle-5{
          right:0;
          top:8px;
          animation-delay:0.6855s;
          -o-animation-delay:0.6855s;
          -ms-animation-delay:0.6855s;
          -webkit-animation-delay:0.6855s;
          -moz-animation-delay:0.6855s;
        }

        &.e-floating-circle-6{
          right:2px;
          bottom:2px;
          animation-delay:0.782s;
          -o-animation-delay:0.782s;
          -ms-animation-delay:0.782s;
          -webkit-animation-delay:0.782s;
          -moz-animation-delay:0.782s;
        }

        &.e-floating-circle-7{
          left:8px;
          bottom:0;
          animation-delay:0.8785s;
          -o-animation-delay:0.8785s;
          -ms-animation-delay:0.8785s;
          -webkit-animation-delay:0.8785s;
          -moz-animation-delay:0.8785s;
        }

        &.e-floating-circle-8{
          left:2px;
          bottom:2px;
          animation-delay:0.975s;
          -o-animation-delay:0.975s;
          -ms-animation-delay:0.975s;
          -webkit-animation-delay:0.975s;
          -moz-animation-delay:0.975s;
        }
        position:absolute;
        background-color:transparent;
        height:3px;
        width:3px;
        border-radius:2px;
        -o-border-radius:2px;
        -ms-border-radius:2px;
        -webkit-border-radius:2px;
        -moz-border-radius:2px;
        animation-name:e-spinner-g;
        -o-animation-name:e-spinner-g;
        -ms-animation-name:e-spinner-g;
        -webkit-animation-name:e-spinner-g;
        -moz-animation-name:e-spinner-g;
        animation-duration:0.782s;
        -o-animation-duration:0.782s;
        -ms-animation-duration:0.782s;
        -webkit-animation-duration:0.782s;
        -moz-animation-duration:0.782s;
        animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
        animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
      }
      position:relative;
      width:19px;
      height:19px;
      margin:auto;
      transform:scale(0.6);
      -o-transform:scale(0.6);
      -ms-transform:scale(0.6);
      -webkit-transform:scale(0.6);
      -moz-transform:scale(0.6);
    }
  }
  &.e-spin-2x{
    &.e-floating-circles-container{

      .e-floating-circles{

        &.e-floating-circle-1{
          left:0;
          top:14px;
          animation-delay:0.2295s;
          -o-animation-delay:0.2295s;
          -ms-animation-delay:0.2295s;
          -webkit-animation-delay:0.2295s;
          -moz-animation-delay:0.2295s;
        }

        &.e-floating-circle-2{
          left:4px;
          top:4px;
          animation-delay:0.316s;
          -o-animation-delay:0.316s;
          -ms-animation-delay:0.316s;
          -webkit-animation-delay:0.316s;
          -moz-animation-delay:0.316s;
        }

        &.e-floating-circle-3{
          left:14px;
          top:0;
          animation-delay:0.3925s;
          -o-animation-delay:0.3925s;
          -ms-animation-delay:0.3925s;
          -webkit-animation-delay:0.3925s;
          -moz-animation-delay:0.3925s;
        }

        &.e-floating-circle-4{
          right:4px;
          top:4px;
          animation-delay:0.469s;
          -o-animation-delay:0.469s;
          -ms-animation-delay:0.469s;
          -webkit-animation-delay:0.469s;
          -moz-animation-delay:0.469s;
        }

        &.e-floating-circle-5{
          right:0;
          top:14px;
          animation-delay:0.5455s;
          -o-animation-delay:0.5455s;
          -ms-animation-delay:0.5455s;
          -webkit-animation-delay:0.5455s;
          -moz-animation-delay:0.5455s;
        }

        &.e-floating-circle-6{
          right:4px;
          bottom:4px;
          animation-delay:0.622s;
          -o-animation-delay:0.622s;
          -ms-animation-delay:0.622s;
          -webkit-animation-delay:0.622s;
          -moz-animation-delay:0.622s;
        }

        &.e-floating-circle-7{
          left:14px;
          bottom:0;
          animation-delay:0.6985s;
          -o-animation-delay:0.6985s;
          -ms-animation-delay:0.6985s;
          -webkit-animation-delay:0.6985s;
          -moz-animation-delay:0.6985s;
        }

        &.e-floating-circle-8{
          left:4px;
          bottom:4px;
          animation-delay:0.775s;
          -o-animation-delay:0.775s;
          -ms-animation-delay:0.775s;
          -webkit-animation-delay:0.775s;
          -moz-animation-delay:0.775s;
        }
        position:absolute;
        background-color:transparent;
        height:6px;
        width:6px;
        border-radius:3px;
        -o-border-radius:3px;
        -ms-border-radius:3px;
        -webkit-border-radius:3px;
        -moz-border-radius:3px;
        animation-name:e-spinner-g;
        -o-animation-name:e-spinner-g;
        -ms-animation-name:e-spinner-g;
        -webkit-animation-name:e-spinner-g;
        -moz-animation-name:e-spinner-g;
        animation-duration:0.622s;
        -o-animation-duration:0.622s;
        -ms-animation-duration:0.622s;
        -webkit-animation-duration:0.622s;
        -moz-animation-duration:0.622s;
        animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
        animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
      }
      position:relative;
      width:35px;
      height:35px;
      margin:auto;
      transform:scale(0.6);
      -o-transform:scale(0.6);
      -ms-transform:scale(0.6);
      -webkit-transform:scale(0.6);
      -moz-transform:scale(0.6);
    }
  }
  &.e-spin-3x{
    &.e-floating-circles-container{

      .e-floating-circles{

        &.e-floating-circle-1{
          left:0;
          top:30px;
          animation-delay:0.2295s;
          -o-animation-delay:0.2295s;
          -ms-animation-delay:0.2295s;
          -webkit-animation-delay:0.2295s;
          -moz-animation-delay:0.2295s;
        }

        &.e-floating-circle-2{
          left:9px;
          top:9px;
          animation-delay:0.316s;
          -o-animation-delay:0.316s;
          -ms-animation-delay:0.316s;
          -webkit-animation-delay:0.316s;
          -moz-animation-delay:0.316s;
        }

        &.e-floating-circle-3{
          left:30px;
          top:0;
          animation-delay:0.3925s;
          -o-animation-delay:0.3925s;
          -ms-animation-delay:0.3925s;
          -webkit-animation-delay:0.3925s;
          -moz-animation-delay:0.3925s;
        }

        &.e-floating-circle-4{
          right:9px;
          top:9px;
          animation-delay:0.469s;
          -o-animation-delay:0.469s;
          -ms-animation-delay:0.469s;
          -webkit-animation-delay:0.469s;
          -moz-animation-delay:0.469s;
        }

        &.e-floating-circle-5{
          right:0;
          top:30px;
          animation-delay:0.5455s;
          -o-animation-delay:0.5455s;
          -ms-animation-delay:0.5455s;
          -webkit-animation-delay:0.5455s;
          -moz-animation-delay:0.5455s;
        }

        &.e-floating-circle-6{
          right:9px;
          bottom:9px;
          animation-delay:0.622s;
          -o-animation-delay:0.622s;
          -ms-animation-delay:0.622s;
          -webkit-animation-delay:0.622s;
          -moz-animation-delay:0.622s;
        }

        &.e-floating-circle-7{
          left:30px;
          bottom:0;
          animation-delay:0.6985s;
          -o-animation-delay:0.6985s;
          -ms-animation-delay:0.6985s;
          -webkit-animation-delay:0.6985s;
          -moz-animation-delay:0.6985s;
        }

        &.e-floating-circle-8{
          left:9px;
          bottom:9px;
          animation-delay:0.775s;
          -o-animation-delay:0.775s;
          -ms-animation-delay:0.775s;
          -webkit-animation-delay:0.775s;
          -moz-animation-delay:0.775s;
        }
        position:absolute;
        background-color: rgb( 254, 254, 254 );
        height:13px;
        width:13px;
        border-radius:7px;
        -o-border-radius:7px;
        -ms-border-radius:7px;
        -webkit-border-radius:7px;
        -moz-border-radius:7px;
        animation-name:e-spinner-g;
        -o-animation-name:e-spinner-g;
        -ms-animation-name:e-spinner-g;
        -webkit-animation-name:e-spinner-g;
        -moz-animation-name:e-spinner-g;
        animation-duration:0.622s;
        -o-animation-duration:0.622s;
        -ms-animation-duration:0.622s;
        -webkit-animation-duration:0.622s;
        -moz-animation-duration:0.622s;
        animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
        animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
      }
      position:relative;
      width:74px;
      height:74px;
      margin:auto;
      transform:scale(0.6);
      -o-transform:scale(0.6);
      -ms-transform:scale(0.6);
      -webkit-transform:scale(0.6);
      -moz-transform:scale(0.6);
    }
  }
  &.e-spin-4x{
    &.e-floating-circles-container{

      .e-floating-circles{

        &.e-floating-circle-1{
          left:0;
          top:44px;
          animation-delay:0.2295s;
          -o-animation-delay:0.2295s;
          -ms-animation-delay:0.2295s;
          -webkit-animation-delay:0.2295s;
          -moz-animation-delay:0.2295s;
        }

        &.e-floating-circle-2{
          left:13px;
          top:13px;
          animation-delay:0.316s;
          -o-animation-delay:0.316s;
          -ms-animation-delay:0.316s;
          -webkit-animation-delay:0.316s;
          -moz-animation-delay:0.316s;
        }

        &.e-floating-circle-3{
          left:44px;
          top:0;
          animation-delay:0.3925s;
          -o-animation-delay:0.3925s;
          -ms-animation-delay:0.3925s;
          -webkit-animation-delay:0.3925s;
          -moz-animation-delay:0.3925s;
        }

        &.e-floating-circle-4{
          right:13px;
          top:13px;
          animation-delay:0.469s;
          -o-animation-delay:0.469s;
          -ms-animation-delay:0.469s;
          -webkit-animation-delay:0.469s;
          -moz-animation-delay:0.469s;
        }

        &.e-floating-circle-5{
          right:0;
          top:44px;
          animation-delay:0.5455s;
          -o-animation-delay:0.5455s;
          -ms-animation-delay:0.5455s;
          -webkit-animation-delay:0.5455s;
          -moz-animation-delay:0.5455s;
        }

        &.e-floating-circle-6{
          right:13px;
          bottom:13px;
          animation-delay:0.622s;
          -o-animation-delay:0.622s;
          -ms-animation-delay:0.622s;
          -webkit-animation-delay:0.622s;
          -moz-animation-delay:0.622s;
        }

        &.e-floating-circle-7{
          left:44px;
          bottom:0;
          animation-delay:0.6985s;
          -o-animation-delay:0.6985s;
          -ms-animation-delay:0.6985s;
          -webkit-animation-delay:0.6985s;
          -moz-animation-delay:0.6985s;
        }

        &.e-floating-circle-8{
          left:13px;
          bottom:13px;
          animation-delay:0.775s;
          -o-animation-delay:0.775s;
          -ms-animation-delay:0.775s;
          -webkit-animation-delay:0.775s;
          -moz-animation-delay:0.775s;
        }
        position:absolute;
        background-color: transparent;
        height:20px;
        width:20px;
        border-radius:10px;
        -o-border-radius:10px;
        -ms-border-radius:10px;
        -webkit-border-radius:10px;
        -moz-border-radius:10px;
        animation-name:e-spinner-g;
        -o-animation-name:e-spinner-g;
        -ms-animation-name:e-spinner-g;
        -webkit-animation-name:e-spinner-g;
        -moz-animation-name:e-spinner-g;
        animation-duration:0.622s;
        -o-animation-duration:0.622s;
        -ms-animation-duration:0.622s;
        -webkit-animation-duration:0.622s;
        -moz-animation-duration:0.622s;
        animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
        animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
      }
      position:relative;
      width:109px;
      height:109px;
      margin:auto;
      transform:scale(0.6);
      -o-transform:scale(0.6);
      -ms-transform:scale(0.6);
      -webkit-transform:scale(0.6);
      -moz-transform:scale(0.6);
    }
  }
}


@keyframes e-spinner-g{
  0%{
    background-color:rgb(254,254,254);
  }

  100%{
    background-color:transparent;
  }
}

@-o-keyframes e-spinner-g{
  0%{
    background-color:rgb(254,254,254);
  }

  100%{
    background-color:transparent;
  }
}

@-ms-keyframes e-spinner-g{
  0%{
    background-color:rgb(254,254,254);
  }

  100%{
    background-color:transparent;
  }
}

@-webkit-keyframes e-spinner-g{
  0%{
    background-color:rgb(254,254,254);
  }

  100%{
    background-color:transparent;
  }
}

@-moz-keyframes e-spinner-g{
  0%{
    background-color:rgb(254,254,254);
  }

  100%{
    background-color:transparent;
  }
}

.elaine-query-builder{
  .elaine-query-builder-inner{
    display: block;
    width: 100%;
    height: auto;
    min-height: 600px;
    overflow: hidden;
  }
  .elaine-group-conditions{
    .elaine-query-builder-condition-group {
      background: #282e2c;
      margin: 10px 0 0 0;
    }
    .elaine-query-builder-condition-default {
      &.even{
        background: #282e2c;
      }
      &.odd{
        background: #1f2523;
      }
      padding: 10px;
      margin: 10px 0 0 0;
    }
    position: relative;
    padding-left: 20px;
  }

  .elaine-query-builder-option{
    &.even{
      background: #1f2523;
    }
    &.odd{
      background: #282e2c;
    }
    .styled-select{
      position: relative;
    }
    .elaine-query-builder-switch{
      .switch-item{
        vertical-align: middle;
      }
      display: inline-block;
      width: auto;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
    }
    .elaine-query-builder-or-select{
      width: 90px;
      display: inline-block;
    }
    .elaine-query-builder-btn{
      &.remove{
        .e_icn{
          color: #c31a1d;
        }
        color: #c31a1d;
        position: absolute;
      }
      .e_icn{
        color: #9dc427;
      }
      margin-left: 5px;
      background: transparent;
      border: none;
      color: #9dc427;
      float: right;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      outline: none;
    }
    display: block;
    padding: 10px;
    width: 100%;
    height: auto;
  }
  .elaine-query-builder-condition {
    &:last-child{
      &:after{
        height: calc( 50% + 10px );
      }
    }
    &:before, &:after {
      content: "";
      position: absolute;
      left: -12px;
    }
    &:before {
      border-top: 1px solid #000;
      top: 50%;
      width: 10px;
      height: 0;
    }
    &:after {
      border-left: 1px solid #000;
      height: calc( 100% + 10px );
      width: 0px;
      top: -10px;
    }
    .form-inline{
      .styled-select{
        &.datafield{
          width: 200px;
        }
        &.operator{
          width: 225px;
          margin-left: 5px
        }
        &.group-operator{
          width: 90px;
        }
        &:hover:not( [disabled] ) {
          &:before {
            background: #9dc427;
          }
        }
        //&:after {
        //  font-family: $iconFontFamily;
        //  content: '[';
        //  color: #fefefe;
        //  right: 8px;
        //  top: 8px;
        //  position: absolute;
        //  pointer-events: none;
        //  z-index: 2;
        //}
        //&:before {
        //  content: '';
        //  right: 0px;
        //  top: 0;
        //  width: 30px;
        //  height: 100%;
        //  background: #404644;
        //  position: absolute;
        //  z-index: 1;
        //  pointer-events: none;
        //  display: block;
        //  border-left: 1px solid #000;
        //}
        display: inline-block;
        position: relative;
      }
      .elaine-query-builder-form-group{
        .input-full{
          width: 100%;
        }
        .ui-spinner{
          input{
            width: calc( 100% - 30px );
          }
          display: inline-block;
          height: 30px;
          width: 100%;
        }
        .date-element {
          &[disabled]{
            .date-toggle {
              &.dropdown-toggle {
                &:hover {
                  .input-group-addon {
                    background: #404644;
                  }
                }
              }
            }
          }
          &.invisible{
            display: none;
          }
          &.timeOnly{
            table{
              &.hour-view, &.minute-view{
                thead{
                  display: none;
                }
              }
            }
          }
          .date-toggle{
            &:hover:not( [disabled] ){
              .input-group-addon {
                background: #9dc427;
              }
            }
            float: left;
            width: calc( 100% - 30px );
            overflow: hidden;
          }
          input {
            display: inline-block;
            width: calc( 100% - 30px );
          }
          span{
            &[disabled]{
              opacity: .5;
            }
            &.form-control{
              &.small {
                width: 20%;
              }
              display: inline-block;
              width: calc( 100% - 30px );
              line-height: 30px;
              padding: 0 5px;
            }
          }
          .date-input-group{
            display: inline-block;
            width: calc( 100% - 33px );
          }
          .input-group-addon{
            &.removeDate{
              &:hover:not( [disabled] ){
                background: #731513;
              }
            }
            &.dateAddon {
              &:hover:not( [disabled] ) {
                background: #9dc427;
              }
            }
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            border: none;
            border-radius: 0;
            background: #404644;
            border-left: 1px solid #000
          }
          width: 100%;
          display: inline-block;
        }
        .elaine-query-builder-input{
          display: inline-block;
          width: 100%;
          height: 30px;
          line-height: 30px;
        }
        .elaine-query-builder-yesno{
          width: 100%;
          .inline-radio {
            float: right;
          }
        }
        .elaine-query-builder-modulo{
          input{
            display: inline-block;
            width: 49%;
            height: 30px;
            line-height: 30px;
          }
          .ui-spinner{
            input{
              width: calc( 100% - 30px );
            }
            flex-grow: 1;
            margin-right: 2px;
            height: 30px;
          }
          display: inline-block;
          width: 100%;
          height: 30px;
          line-height: 30px;
        }
        .styled-select{
          .chosen-container{
            &.chosen-container-multi{
              &:before{
                height: 60px;
              }
              .chosen-choices{
                height: 60px;
                min-height: 60px;
              }
              .chosen-scroll-choices{
                .chosen-choices{
                  min-height: 60px;
                }
                height: 60px;
              }
              height: 60px;
            }
          }

        }
        .input-group-addon{
          &:hover{
            background: #9dc427;
          }
        }
        .input-group-addon {
          &.removeDate {
            &:hover:not( [disabled] ) {
              background: #731513;
            }
          }
        }
        display: inline-block;
        width: calc( 100% - 475px );
        height: auto;
        margin-left: 5px;
        vertical-align: top;
        position: relative;
      }
    }
    position: relative;
  }
  &.full{
    height: 650px;
  }
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  min-width: 775px;
  overflow:hidden;
}

.moxman-filelist-body{
  overflow-y: auto !important;
}

.elaine-stage-loading{
  &.ng-hide-add,&.ng-hide-remove{
    -webkit-transition: .35s ease-in-out all;
    -moz-transition: .35s ease-in-out all;
    -ms-transition: .35s ease-in-out all;
    -o-transition: .35s ease-in-out all;
    transition: .35s ease-in-out all;
  }
  &.ng-hide{
    opacity: 0;
  }
  .elaine-spinner{
    top: 33%;
  }
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
}

.tab-content{
  .tab-pane{
    height: 100%;
  }
  height: calc( 100% - 32px );
}


/*new bootstrap needs to be rechecked and placed in the right positions*/
.navbar{
  margin-bottom: 0;
  min-height: 0;
}

.navbar-default{
  border: none;
}

.nav > li > a {
  display: block;
  padding: 0;
  padding-left: 5px;
  position: relative;
}

.nav-tabs > li > a {
  border: none;
  border-radius: 0;
  margin-right: 0;
}




/* Hides from IE-mac \*/
* html .clearfix {height: 1%;}
.clearfix {display: block;}
/* End hide from IE-mac */

.hide:not(md-input-container) {
  position: absolute;
  height: 1px;
  width:1px;
  margin-left: -3000px;
  margin-top: -3000px;
}

